<template>
  <v-dialog
    v-model="isOpened"
    max-width="800"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-2" no-gutters>
        <v-col align="center">
          <span class="text-h6 font-weight-bold">
            Информация о изменениях статуса
          </span>
        </v-col>
      </v-row>
      <v-row v-if="itemCopy" class="bids-table-container" no-gutters>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="history"
            :loading="isRequestWaiting"
          >
            <template v-slot:item.PreviousStatus="{ item }">
              {{
                `${item.PreviousStatus.name} (${item.PreviousStatus.description})`
              }}
            </template>
            <template v-slot:item.NewStatus="{ item }">
              {{ `${item.NewStatus.name} (${item.NewStatus.description})` }}
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ getFormatDateTime(item.createdAt) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import moment from "moment";

import { GET_USER_URL } from "../../../config/http";

export default {
  name: "HistoryProcessPopUp",
  mixins: [PopUpMixin],
  data: () => ({
    headers: [
      { text: "Предыдущий статус", value: "PreviousStatus", sortable: false },
      { text: "Новый статус", value: "NewStatus", sortable: false },
      { text: "Дата", value: "createdAt", sortable: false },
    ],
    history: [],
  }),
  methods: {
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.history = [];
        this.getUserFromApi();
      }
    },
    getUserFromApi() {
      this.isRequestWaiting = true;
      this.$http
        .get(GET_USER_URL(this.itemCopy.id))
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.history = response.data.item.StatusHistory;
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    getFormatDateTime(dateTime) {
      return moment(dateTime).calendar();
    },
    getRoleById(roleId) {
      const role = this.$store.getters.roles.find((item) => {
        return roleId === item.roleId;
      });
      return role.name;
    },
  },
  computed: {},
};
</script>

<style scoped>
.bids-table-container {
  max-height: 500px;
  overflow: auto;
}
</style>
