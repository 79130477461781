export default {
  maxLength: (len) => (v) =>
    (v || "").length <= len || `Maximum number of characters ${len}`,
  required: [(value) => !!value || "Обязательное поле"],
  requiredNumber: [
    (value) =>
      (value !== null && value !== undefined && value !== "") ||
      "Обязательное поле",
  ],
  notNull: [(value) => value !== null || "Обязательное поле"],
  email: [(value) => /.+@.+\..+/.test(value) || "E-mail не корректен"],
  noSpaces: [
    (value) => (value || "").indexOf(" ") < 0 || "Не должно быть пробелов",
  ],
  positiveNumber: [
    (value) => parseInt(value) >= 0 || "Число должно быть положительным",
  ],
  customPhoneNumber: [
    (value) => {
      /*eslint-disable-next-line*/
      return !isNaN(value.replace(/[()\-+\ ]/g, "")) || "Должны быть только числа"; // returns true if the variable does NOT contain a valid number
    }, 
    (value) =>  value.length > 17 || "Введите номер полностью",
  ],
};
