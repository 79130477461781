<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import moment from "moment";
import localization from "moment/locale/ru";

export default {
  name: "App",
  data: () => ({}),
  created() {
    this.setLocalizations();
  },
  methods: {
    setLocalizations() {
      moment.updateLocale("ru", localization);
    },
  },
};
</script>

<style>
@import "./styles/App.css";
</style>
