<template>
  <v-dialog v-model="isOpened" max-width="600" @click:outside="closePopUp" persistent>
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Изменить документ </span>
        </v-col>
      </v-row>
      <v-form
        v-if="selectedItem"
        v-model="isValid"
        @submit.prevent="fakeLoad"
        ref="form"
      >
        <v-row no-gutters>
          <v-col>
            <v-text-field
              :value="selectedItem.name"
              label="Название"
              placeholder="Название"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";

export default {
  name: "EditDocumentPopUp",
  mixins: [PopUpMixin],
  data: () => ({
  }),
  methods: {
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        this.$nextTick(() => {
          this.$refs.form?.resetValidation();
        });
      }
    },
  },
};
</script>

<style scoped></style>
