<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="card-container-pop-up px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Изменить аккаунт </span>
        </v-col>
      </v-row>
      <v-form v-model="isValid" @submit.prevent="submitHandler" ref="form">
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission ||
              iAmSaleHead ||
              iAmCRMManager ||
              iAmSaleTeam ||
              iAmRetHead ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedisClient"
              @input="updateLocalItem(itemCopy, 'isClient', $event)"
              :items="isClient"
              item-text="name"
              item-value="value"
              label="Лид/клиент:"
              placeholder="Назначить лидом/клиентом"
              :loading="isRequestIsClientWaiting"
              ref="isClient"
              @dblclick="$refs.isClient.blur()"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission ||
              iAmSupport ||
              iAmRetHead ||
              iAmSaleHead ||
              iAmCRMManager)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedAccountStatus"
              :items="getAccountStatuses"
              item-text="name"
              item-value="id"
              label="Статус аккаунта"
              placeholder="Статус аккаунта"
              :loading="isRequestCallCentersWaiting"
              ref="statusAccount"
              @dblclick="$refs.statusAccount.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="itemCopy && (isSuperSuperAdminPermission || iAmCRMManager)"
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedCallCenter"
              :items="callCenters"
              @change="onCallCenterChanged"
              item-text="name"
              item-value="id"
              label="Колл-центр"
              placeholder="Колл-центр"
              :loading="isRequestCallCentersWaiting"
              ref="callCenter"
              @dblclick="$refs.callCenter.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row
          v-if="itemCopy && selectedCallCenter !== null && isSuperAdminPermission"
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedTeam"
              :items="teams"
              item-text="name"
              item-value="id"
              label="Команда"
              placeholder="Команда"
              :loading="isRequestTeamsWaiting"
              ref="team"
              @dblclick="$refs.team.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row
          v-if="
            itemCopy &&
            selectedCallCenter &&
            (isSuperAdminPermission || iAmCRMManager)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedAffManager"
              :items="affManagers"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} (${item.email})`
              "
              item-value="id"
              label="Aff Manager"
              placeholder="Aff Manager"
              :loading="isRequestManagerWaiting"
              ref="affManagerAdmin"
              @dblclick="$refs.affManagerAdmin.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            selectedCallCenter &&
            (isSuperAdminPermission ||
              iAmCRMManager ||
              iAmSaleTeam ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedSaleManager"
              :items="saleManagers"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} (${item.email})`
              "
              item-value="id"
              label="Sale Manager"
              placeholder="Sale Manager"
              :loading="isRequestManagerWaiting"
              ref="saleAdmin"
              @dblclick="$refs.saleAdmin.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            selectedCallCenter &&
            (isSuperAdminPermission ||
              iAmCRMManager ||
              iAmRetTeam ||
              iAmRetHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedRetManager"
              :items="retManagers"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} (${item.email})`
              "
              item-value="id"
              label="Ret Manager"
              placeholder="Ret Manager"
              :loading="isRequestManagerWaiting"
              ref="retManager"
              @dblclick="$refs.retManager.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission ||
              iAmCRMManager ||
              iAmSale ||
              iAmRet ||
              iAmRetTeam ||
              iAmSaleTeam ||
              iAmRetHead ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedProcessedStatus"
              :items="getProcessedStatuses"
              :item-text="(item) => renderProcessingcStatus(item)"
              item-value="id"
              label="Статус обработки"
              placeholder="Статус обработки"
              :loading="isRequestManagerWaiting"
              ref="processedStatus"
              @dblclick="$refs.processedStatus.blur()"
            >
              <template v-slot:selection="data">
                <v-badge
                  inline
                  left
                  dot
                  :color="processingStatusColor(data.item.id)"
                >
                  {{ data.item.name }}
                </v-badge>
              </template>

              <template v-slot:item="data">
                <v-badge
                  inline
                  left
                  dot
                  :color="processingStatusColor(data.item.id)"
                >
                  {{ data.item.name }}
                </v-badge>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission ||
              iAmCRMManager ||
              iAmSupport ||
              iAmRetTeam ||
              iAmRetHead ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              v-model="selectedPhoneStatus"
              :items="getPhoneStatuses"
              item-text="name"
              item-value="id"
              label="Статус верификации телефона"
              placeholder="Статус верификации телефона"
              ref="phoneStatus"
              @dblclick="$refs.phoneStatus.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-4" no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  GET_UPDATE_USER_URL,
  GET_ALL_CALL_CENTERS_URL,
  // GET_TEAMS_IN_CALLCENTER_URL,
  GET_ALL_ADMINS_IN_CALLCENTER_URL,
} from "../../../config/http";

import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import ProcessingStatusMixin from "../../../mixins/ProcessingStatusMixin";

export default {
  name: "EditSeveralUsersPopUp",
  mixins: [PopUpMixin, RoleMixin, ProcessingStatusMixin],
  data: () => ({
    selectedAccountStatus: null,
    selectedProcessedStatus: null,
    selectedPhoneStatus: null,
    // selectedTeam: null,
    selectedCallCenter: null,
    selectedAffManager: null,
    selectedSaleManager: null,
    selectedRetManager: null,
    // teams: [],
    callCenters: [],
    affManagers: [],
    saleManagers: [],
    retManagers: [],
    isRequestCallCentersWaiting: false,
    // isRequestTeamsWaiting: false,
    isRequestManagerWaiting: false,
    isClient: [
      { value: true, name: "Клиент" },
      { value: false, name: "Лид" },
    ],
  }),
  methods: {
    submitAction() {
      const body = {};
      if (this.selectedCallCenter)
        body["callCenterId"] = this.selectedCallCenter;
      // if (this.selectedTeam) body["teamId"] = this.selectedTeam;
      if (this.selectedRetManager) body["retAdminId"] = this.selectedRetManager;
      if (this.selectedSaleManager)
        body["saleAdminId"] = this.selectedSaleManager;
      if (this.selectedAffManager)
        body["affManagerAdminId"] = this.selectedAffManager;
      if (this.selectedProcessedStatus)
        body["processingStatusId"] = this.selectedProcessedStatus;
      if (this.selectedPhoneStatus)
        body["phoneStatusId"] = this.selectedPhoneStatus;
      if (this.selectedAccountStatus)
        body["accountStatusId"] = this.selectedAccountStatus;
      if (this.selectedisClient || !this.selectedisClient)
        body["isClient"] = this.selectedisClient;

      const usersId = this.itemCopy.map((item) => item.id).join(",");
      console.log(usersId);
      console.log(body);

      this.$http
        .patch(GET_UPDATE_USER_URL(usersId), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.$emit("update", response.data.items);
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = [...this.selectedItem];
        }
        // this.teams = [];
        this.callCenters = [];
        this.saleManagers = [];
        this.retManagers = [];
        if (this.isSuperSuperAdminPermission || this.iAmCRMManager) {
          this.getCallCentersFromApi();
        } else if (!this.iAmSupport && !this.iAmSale) {
          this.selectedCallCenter = this.itemCopy[0].callCenterId;
          this.onCallCenterChanged(this.selectedCallCenter);
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    },
    getCallCentersFromApi() {
      this.callCenters = [];
      this.isRequestCallCentersWaiting = true;
      this.$http
        .get(GET_ALL_CALL_CENTERS_URL())
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.callCenters = data.data.items;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestCallCentersWaiting = false;
        });
    },
    // getTeamsForCallCenter() {
    //   this.isRequestTeamsWaiting = true;
    //   const params = {
    //     callCenterId: this.selectedCallCenter,
    //   };
    //   this.$http
    //     .get(GET_TEAMS_IN_CALLCENTER_URL(), { params })
    //     .then((response) => {
    //       response.json().then((data) => {
    //         console.log(data);
    //         this.teams = data.data.items;
    //       });
    //     })
    //     .catch((e) => {
    //       console.error(e.message);
    //     })
    //     .finally(() => {
    //       this.isRequestTeamsWaiting = false;
    //     });
    // },
    getAdminsForCallCenter() {
      this.isRequestManagerWaiting = true;
      const params = {
        callCenterId: this.selectedCallCenter,
      };
      this.$http
        .get(GET_ALL_ADMINS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.affManagers = data.data.items.filter((item) => {
              return item.roleId === this.getAffManagerRole.id;
            });
            this.retManagers = data.data.items.filter((item) => {
              return item.roleId === this.getRetRole.id;
            });
            this.saleManagers = data.data.items.filter((item) => {
              return item.roleId === this.getSaleRole.id;
            });
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestManagerWaiting = false;
        });
    },
    onCallCenterChanged(value) {
      console.log(value);
      if (value !== null) {
        // this.teams = [];
        this.admins = [];
        // this.getTeamsForCallCenter();
        this.getAdminsForCallCenter();
      }
    },
  },
  computed: {
    getCountries() {
      return this.$store.getters.countries;
    },
    getProcessedStatuses() {
      return this.$store.getters.processingStatus;
    },
    getPhoneStatuses() {
      return this.$store.getters.phoneStatus.map((item) => {
        item["disabled"] = item.id === 3 || item.id === 4 ? true : false;
        return item;
      });
    },
    getAccountStatuses() {
      return this.$store.getters.accountStatus;
    },
  },
  props: {
    selectedItem: Array,
  },
};
</script>

<style scoped></style>
