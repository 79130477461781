<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col>
          <v-img
            :src="documentUrl || null"
            alt="Документ"
            max-height="400"
            @click="onClickImageHandler"
            style="cursor: pointer"
          >
          </v-img>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-btn color="primary" @click="downloadImage" x-small>Скачать</v-btn>
        </v-col>
        <v-col>
          <v-btn color="primary" @click="replaceFile" x-small>Заменить</v-btn>
        </v-col>
        <v-col>
          <v-btn color="error" @click="removeImage" x-small>Удалить</v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import {} from "../../../config/http";

export default {
  name: "DocumentCard",
  data: () => ({}),
  methods: {
    onClickImageHandler() {
      this.$emit("onImageClick", this.documentUrl);
    },
    downloadImage() {
      //download file
    },
    removeImage() {
      //remove file
    },
    replaceFile() {
      //replace file
    },
  },
  computed: {},
  props: {
    documentUrl: String,
  },
};
</script>

<style scoped></style>
