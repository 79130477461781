<template>
  <span>
    {{ header.text }}
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon small :color="filterQuery ? 'primary' : ''">
            mdi-filter
          </v-icon>
        </v-btn>
      </template>
      <div style="background-color: white; width: 280px">
        <v-text-field
          v-model="filterQuery"
          class="pl-4 pr-4"
          type="text"
          :label="header.text"
          :autofocus="true"
          append-icon="mdi-close"
          @click:append="clear"
        ></v-text-field>
        <v-btn block @click="submit" color="primary"> Search </v-btn>
      </div>
    </v-menu>
  </span>
</template>

<script>
export default {
  name: "FilterComponent",
  mixins: [],
  props: {
    header: Object,
  },
  data: () => ({
    filterQuery: "",
  }),
  methods: {
    clear() {
      this.filterQuery = "";
      this.$emit("input", "");
      this.$emit("update");
    },
    submit() {
      if (this.filterQuery.length >= 1) {
        this.$emit("input", this.filterQuery);
        this.$emit("update");
      } else {
        this.$emit("input", null);
        this.$emit("update");
      }
    },
  },
};
</script>

<style></style>
