import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import cookies from "./plugins/vuecookies";
import store from "./plugins/vuex";
import vueresource from "./plugins/vueresource";
import "./plugins/vuetifymask";

import router from "./router/index.js";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  cookies,
  vueresource,
  render: (h) => h(App),
}).$mount("#app");
