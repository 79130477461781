<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Создать команду </span>
        </v-col>
      </v-row>
      <v-form v-model="isValid" @submit.prevent="submitAction" ref="form">
        <v-row class="mb-4" no-gutters>
          <v-col>
            <v-text-field
              v-model="name"
              label="Название"
              placeholder="Название"
              :rules="[...rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Создать
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import { GET_ADD_TEAM_IN_CALLCENTER_URL } from "../../../config/http";
import appUIService from "../../../utils/appUIService";

export default {
  name: "CreateTeamPopUp",
  mixins: [PopUpMixin],
  data: () => ({
    name: "",
  }),
  methods: {
    submitAction() {
      this.isRequestWaiting = true;
      const body = {
        name: this.name,
        callCenterId: this.callCenterId,
      };
      this.$http
        .post(GET_ADD_TEAM_IN_CALLCENTER_URL(), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.isRequestWaiting = false;
            console.log(appUIService.getInstance().teamAddedCallback);
            appUIService.getInstance().teamAddedCallback(response.data.item);
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    },
  },
  props: {
    callCenterId: Number,
  },
};
</script>

<style scoped></style>
