<template>
  <v-row class="mb-2" justify="space-between" align="center">
    <v-col cols="auto">
      <v-row class="mb-2" justify="end" align="center">
        <v-col cols="auto">
          <v-text-field
            label="Поиск"
            v-model="searchQuery"
            append-icon="mdi-close"
            @click:append="clear"
          ></v-text-field>
        </v-col>

        <v-col cols="auto">
          <v-btn @click="search" color="primary"> Поиск </v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="auto"
      v-if="
        isSeveralItemsSelected !== null && isSeveralItemsSelected !== undefined
      "
    >
      <v-row class="mb-2" justify="end">
        <v-col cols="auto">
          <v-btn
            @click="$emit('edit')"
            color="primary"
            :disabled="!isSeveralItemsSelected"
          >
            Редактировать выбранные
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn
            v-if="isSuperAdminPermission"
            @click="$emit('delete')"
            color="error"
            :disabled="!isSeveralItemsSelected"
          >
            Удалить выбранные
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import RoleMixin from "../../mixins/RoleMixin";

export default {
  name: "DataTableTop",
  mixins: [RoleMixin],

  props: {
    isSeveralItemsSelected: Boolean || null || undefined,
  },
  data: () => ({
    searchQuery: "",
  }),
  methods: {
    clear() {
      this.searchQuery = "";
      this.$emit("input", "");
      this.$emit("update");
    },
    search() {
      this.$emit("input", this.searchQuery);
      this.$emit("update");
    },
  },
};
</script>

<style></style>
