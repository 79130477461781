<template>
  <v-row class="pa-10" no-gutters>
    <v-col>
      <v-card class="pa-5" elevation="4">
        <v-row no-gutters>
          <v-col>
            <v-row>
              <v-col>
                <v-row class="mb-2" justify="end">
                  <v-col align="start" v-if="true">
                    <v-btn
                      @click="isUploadDocumentOpened = true"
                      color="primary"
                    >
                      Добавить документ
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      @click="downloadSeveralDocuments()"
                      color="primary"
                      :disabled="!isSeveralItemsSelected"
                    >
                      Скачать выбранные
                    </v-btn>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      @click="openDeletePopUp()"
                      color="error"
                      :disabled="!isSeveralItemsSelected"
                    >
                      Удалить выбранные
                    </v-btn>
                  </v-col>
                </v-row>

                <v-data-table
                  v-model="selectedItems"
                  :headers="headers"
                  :items="items"
                  @click:row="documentClickHandler"
                  :loading="isRequestWaiting"
                  :options.sync="options"
                  :server-items-length="totalDocuments"
                  :footer-props="{
                    'items-per-page-options': rowsPerPageItems,
                  }"
                  show-select
                >
                  <template v-slot:item.icon="{}">
                    <v-icon color="primary">mdi-file-document-outline</v-icon>
                  </template>
                  <template v-slot:item.edit="{ item }">
                    <v-btn @click.stop="openEditDocument(item)" color="primary">
                      Редактировать
                    </v-btn>
                  </template>
                  <template v-slot:item.delete="{ item }">
                    <v-btn
                      @click.stop="openDeletePopUp(item)"
                      class="white--text"
                      color="red"
                    >
                      Удалить
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col v-for="item in items" :key="item.id">
            <img :src="item.url" alt="" />
          </v-col>
        </v-row> -->
      </v-card>
    </v-col>
    <EditDocumentPopUp
      :isOpened="isEditDocumentOpened"
      :selectedItem="selectedDocument"
      @close="closeEditDocument"
    ></EditDocumentPopUp>
    <UploadDocumentPopup
      :isOpened="isUploadDocumentOpened"
      :selectedItem="selectedDocument"
      @close="closeUploadDocument"
    ></UploadDocumentPopup>
    <ConfirmPopUp
      :question="getDeleteQuestion"
      :isOpened="isDeleteDocumentOpened"
      @yesAction="closeDeletePopUp"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-row>
</template>

<script>
import EditDocumentPopUp from "../documentPage/popUps/EditDocumentPopUp.vue";
import UploadDocumentPopup from "../documentPage/popUps/UploadDocumentPopup.vue";
import ConfirmPopUp from "../shared/ConfirmPopUp.vue";

import {
  GET_ALL_DOCUMENTS_URL,
  // GET_DELETE_DOCUMENT_URL,
} from "../../config/http";

import DataTableMixin from "../../mixins/DataTableMixin";
import RoleMixin from "../../mixins/RoleMixin";

export default {
  name: "DocumentsPage",
  mixins: [DataTableMixin, RoleMixin],
  data: () => ({
    headers: [
      { text: "", value: "icon", sortable: false },
      { text: "Название", value: "name", sortable: false },
      { text: "Тип", value: "type", sortable: false },
      { text: "Размер", value: "size", sortable: false },
      { text: "", value: "edit", sortable: false, width: 100 },
      { text: "", value: "delete", sortable: false, width: 100 },
    ],
    documents: [
      {
        id: 0,
        name: "Документ 1",
        type: "Документ",
        size: "4KB",
      },
      {
        id: 1,
        name: "Документ 2",
        type: "Документ",
        size: "5KB",
      },
      {
        id: 2,
        name: "Изображение 1",
        type: "Изображение",
        size: "200KB",
      },
      {
        id: 3,
        name: "Изображение 2",
        type: "Изображение",
        size: "300KB",
      },
    ],
    totalDocuments: 2,
    options: {},
    rowsPerPageItems: [5, 10, 20, 50, 100],
    selectedDocument: null,
    isEditDocumentOpened: false,
    isDeleteDocumentOpened: false,
    isUploadDocumentOpened: false,
    isRequestWaiting: false,
    selectedItems: [],
    response: null,
  }),
  methods: {
    bytesToSize(bytes) {
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes === 0) return "n/a";
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
      if (i === 0) return `${bytes} ${sizes[i]})`;
      return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
    },
    getDocumentsFromApi(userId) {
      const request = require("request");
      userId = 2;
      this.isRequestWaiting = true;

      const params = this.getQueryParams();

      this.$http
        .get(GET_ALL_DOCUMENTS_URL(userId), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            const response = data.data.item.documents;
            this.response = response;
            const documents = [];
            response.map((item, index) => {
              const longName = item;
              let name = longName;
              let type = longName.split("-")[5];
              let size = "i";

              try {
                request(
                  {
                    url: item,
                    // url: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/core.js",
                    method: "HEAD",
                  },
                  (err, response) => {
                    if (response) {
                      size = this.bytesToSize(
                        parseInt(response.headers["content-length"])
                      );

                      documents.push({
                        id: index,
                        name: name,
                        type: type,
                        size: size,
                        url: item,
                      });
                    } else {
                      documents.push({
                        id: index,
                        name: name,
                        type: type,
                        size: "no data",
                        url: item,
                      });
                    }

                    return;
                  }
                );
              } catch (e) {
                console.error(e);
              }
            });

            this.items = documents;
            this.totalItems = response.length;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    documentClickHandler(item) {
      // console.log(item);
      window.open(item.url, "_blank").focus();
    },
    openEditDocument(Document) {
      this.selectedDocument = Document;
      this.isEditDocumentOpened = true;
    },
    closeEditDocument() {
      this.isEditDocumentOpened = false;
      this.selectedDocument = null;
    },
    openDeletePopUp(item) {
      this.selectedDocument = item;
      this.isDeleteDocumentOpened = true;
    },
    closeDeletePopUp() {
      this.isDeleteDocumentOpened = false;
      this.selectedDocument = null;
    },
    closeUploadDocument() {
      this.isUploadDocumentOpened = false;
      this.selectedDocument = null;
    },
    watcherHandler() {
      this.getDocumentsFromApi();
    },
    downloadSeveralDocuments() {
      // console.log(this.selectedItems);
    },
    // deleteDocuments(userId) {
    // this.isRequestWaiting = true;
    // userId = 146;
    // console.log(this.response);

    // const body = {
    //   documents: this.response.splice(),
    // };
    // this.$http
    //   .path(GET_DELETE_DOCUMENT_URL(userId), body)
    //   .then((response) => {
    //     response.json().then((response) => {
    //       console.log(response);
    //       this.isRequestWaiting = false;
    //       // this.deleteSeveralHandler(this.selectedItems);
    //       // this.selectedItems = [];
    //       // this.getDocumentsFromApi();
    //     });
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //     this.isRequestWaiting = false;
    //   });
    // },
  },
  computed: {
    isSeveralItemsSelected() {
      return this.selectedItems.length > 0;
    },
    getDeleteQuestion() {
      return this.selectedItems.length > 1
        ? "Вы действительно хотите удалить эти документы?"
        : "Вы действительно хотите удалить этот документ?";
    },
  },
  components: {
    EditDocumentPopUp,
    UploadDocumentPopup,
    ConfirmPopUp,
  },
  watch: {
    selectedItems(n) {
      console.log(n);
    },
  },
};
</script>

<style></style>
