<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Изменить команду </span>
        </v-col>
      </v-row>
      <v-form
        v-if="selectedItem"
        v-model="isValid"
        @submit.prevent="submitHandler"
        ref="form"
      >
        <v-row no-gutters>
          <v-col>
            <v-text-field
              @input="updateLocalItem(itemCopy, 'name', $event)"
              :value="itemCopy.name"
              label="Название"
              placeholder="Название"
              :rules="[...rules.required]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import { GET_UPDATE_TEAM_IN_CALLCENTER_URL } from "../../../config/http";

export default {
  name: "EditTeamPopUp",
  mixins: [PopUpMixin],
  data: () => ({}),
  methods: {
    submitAction() {
      const body = {
        name: this.itemCopy.name,
        callCenterId: this.itemCopy.callCenterId,
      };
      const params = {
        id: this.itemCopy.id,
      };

      this.$http
        .patch(
          GET_UPDATE_TEAM_IN_CALLCENTER_URL(this.itemCopy.id),
          body,
          params
        )
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.isRequestWaiting = false;
            this.$emit("update", this.itemCopy);
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  computed: {
    getAdminRoles() {
      return this.$store.getters.roles;
    },
  },
};
</script>

<style scoped></style>
