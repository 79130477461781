import Rules from "../config/rules";
import moment from "moment";

export default {
  name: "PopUpMixin",
  data: () => ({
    itemCopy: null,
    isRequestWaiting: false,
    isValid: false,
    rules: Rules,
    success: false,
    error: false,
  }),
  methods: {
    submitHandler() {
      this.isRequestWaiting = true;
      const isValid = this.$refs.form?.validate();
      if (isValid) {
        this.submitAction();
      } else {
        console.warn("Fields not valid!");
        this.isRequestWaiting = false;
      }
    },
    submitAction() {
      console.log("Fake Submit");
      this.fakeLoad();
    },
    updateLocalItem(obj, prop, value) {
      if (this.isOpened) this.$set(obj, prop, value);
    },
    closePopUp() {
      this.success = false;
      this.error = false;
      this.$emit("close");
    },
    fakeLoad() {
      this.isRequestWaiting = true;
      setTimeout(() => {
        this.$emit("close");
        this.isRequestWaiting = false;
      }, 1000);
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          // console.log(this.selectedItem);
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form?.reset();
        });
      }
    },
    getFormatDate(dateTime) {
      if (!dateTime) {
        return "";
      }
      return moment(dateTime).format("YYYY-MM-DD HH:mm");
    },
    renderProcessingcStatus(item) {
      return `${item.name} ${item.description ? `(${item.description})` : ""} `;
    },
  },
  watch: {
    isOpened(value) {
      this.isOpenedWatcher(value);
    },
  },
  props: {
    isOpened: Boolean,
    selectedItem: Object || null || undefined,
  },
};
