const HOST = process.env.VUE_APP_API_SERVER;

const PUBLIC_URL = "public/";
// const USER_URL = "user/";
const ADMIN_URL = "admin/";

export const GET_AUTHORIZATION_URL = () => `${HOST}${PUBLIC_URL}login/admin`;
export const GET_CONSTANTS_URL = () => `${HOST}${PUBLIC_URL}constants`;

export const GET_WHOAMI_URL = () => `${HOST}${ADMIN_URL}whoami`;

export const GET_ALL_USERS_URL = () => `${HOST}${ADMIN_URL}users`;
export const GET_USER_URL = (id) => `${HOST}${ADMIN_URL}users/${id}`;
export const GET_USER_PHONE_DETAILS_URL = (id) => `${HOST}${ADMIN_URL}users/${id}/phone-details`;
export const GET_USER_PHONE_CAll_START_URL = (id) => `${HOST}${ADMIN_URL}users/${id}/call-start`;
export const GET_LOG_IN_AS_USER_URL = (id) => `${HOST}${ADMIN_URL}users/${id}`;
export const GET_INSERT_USER_URL = () => `${HOST}${PUBLIC_URL}register`;
export const GET_UPDATE_USER_URL = (id) => `${HOST}${ADMIN_URL}users/${id}`;
export const GET_UPDATE_BALANCE_USER_URL = (id) =>
  `${HOST}${ADMIN_URL}users/${id}/balance`;
export const GET_UPDATE_PASSWORD_URL = (id) =>
  `${HOST}${ADMIN_URL}users/${id}/password`;

export const GET_BIDS_USER_URL = (id) => `${HOST}${ADMIN_URL}users/${id}/bids`;
export const CREATE_SUBSTITUTION_RATE_FOR_USER_URL = (id) =>
  `${HOST}${ADMIN_URL}users/${id}/substitutions`;
export const GET_ACTIONS_USER_URL = (id) =>
  `${HOST}${ADMIN_URL}users/${id}/actions`;

export const GET_CALL_CENTER_URL = (id) =>
  `${HOST}${ADMIN_URL}callcenters/${id}`;
export const GET_ALL_CALL_CENTERS_URL = () => `${HOST}${ADMIN_URL}callcenters`;
export const GET_ALL_PLATFORMS_URL = () => `${HOST}${ADMIN_URL}platform`;
export const GET_INSERT_CALL_CENTER_URL = () =>
  `${HOST}${ADMIN_URL}callcenters`;
export const GET_UPDATE_CALL_CENTER_URL = (id) =>
  `${HOST}${ADMIN_URL}callcenters/${id}`;
export const GET_DELETE_CALL_CENTER_URL = (id) =>
  `${HOST}${ADMIN_URL}callcenters/${id}`;

export const GET_ALL_ADMINS_IN_CALLCENTER_URL = () =>
  `${HOST}${ADMIN_URL}admins`;
export const GET_ADMIN_IN_CALLCENTER_URL = (id) =>
  `${HOST}${ADMIN_URL}admins/${id}`;
export const GET_ADD_ADMIN_URL = () => `${HOST}${ADMIN_URL}admins`;
export const GET_UPDATE_ADMIN_URL = (adminId) =>
  `${HOST}${ADMIN_URL}admins/${adminId}`;
export const GET_DELETE_ADMIN_URL = (adminId) =>
  `${HOST}${ADMIN_URL}admins/${adminId}`;

export const GET_TEAMS_IN_CALLCENTER_URL = () => `${HOST}${ADMIN_URL}teams`;
export const GET_TEAM_IN_CALLCENTER_URL = (id) =>
  `${HOST}${ADMIN_URL}teams/${id}`;
export const GET_ADD_TEAM_IN_CALLCENTER_URL = () => `${HOST}${ADMIN_URL}teams`;
export const GET_UPDATE_TEAM_IN_CALLCENTER_URL = (teamId) =>
  `${HOST}${ADMIN_URL}teams/${teamId}`;
export const GET_DELETE_TEAM_IN_CALLCENTER_URL = (teamId) =>
  `${HOST}${ADMIN_URL}teams/${teamId}`;

export const GET_ALL_DOCUMENTS_URL = (id) => `${HOST}${ADMIN_URL}users/${id}`;
export const GET_UPLOAD_DOCUMENT_URL = (name) =>
  `${HOST}${ADMIN_URL}files?filename=${name}`;
export const GET_DELETE_DOCUMENT_URL = (id) => `${HOST}${ADMIN_URL}users/${id}`;

export const GET_ADD_COMMENT_URL = (id) =>
  `${HOST}${ADMIN_URL}users/${id}/comments`;
export const GET_DELETE_COMMENT_URL = (userId, commentId) =>
  `${HOST}${ADMIN_URL}users/${userId}/comments/${commentId}`;

export const GET_UPDATE_CREDIT_URL = (id) => `${HOST}${ADMIN_URL}users/${id}`;

export const GET_DEFAULT_LEVERAGE_URL = () => `${HOST}${ADMIN_URL}leverage`;

export const GET_TELTEL_KEY_URL = (id) =>
  `${HOST}${ADMIN_URL}users/${id}/calls`;

export const GET_CREATE_REMINDER_URL = () => `${HOST}${ADMIN_URL}reminders`;
export const GET_MY_REMINDER_URL = () => `${HOST}${ADMIN_URL}reminders`;
export const GET_DELETE_REMINDER_URL = (id) =>
  `${HOST}${ADMIN_URL}reminders/${id}`;

export const GET_WS_REMINDER_URL = (token) =>
  `ws://127.0.0.1:8003/admins-connect?token=${token}`;
export const GET_WSS_REMINDER_URL = (token) =>
  `wss://soulcapital.group/admins-connect?token=${token}`;

export const GET_BULK_LOAD_URL = () => `${HOST}${ADMIN_URL}users/bulk-load`;

export const GET_DEPOSIT_MERCHANT_URL = () => `${HOST}${ADMIN_URL}constants`;

export const GET_DEFAULT_PLATFORM_URL = () => `${HOST}${ADMIN_URL}constants`;

export const GET_PAY_REQUESTS_URL = () => `${HOST}${ADMIN_URL}pay-requests`;

export const PATCH_PAY_REQUESTS_URL = () => `${HOST}${ADMIN_URL}pay-requests`;

export const CHANGE_BID_PRICE_REQUESTS_URL = (userId, bidId) =>
  `${HOST}${ADMIN_URL}users/${userId}/bids/${bidId}`;
