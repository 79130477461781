<template>
  <v-row no-gutters>
    <v-col>
      <v-row>
        <v-col>
          <!-- <DataTableTop
            :isSeveralItemsSelected="isSeveralItemsSelected"
            @search="search($event)"
            @edit="openEditSeveralUsersPopUp()"
            @delete="openDeletePopUp()"
          ></DataTableTop> -->
          <v-data-table
            class="clickable-table"
            v-model="selectedItems"
            :headers="headers"
            :items="items"
            :page="currentPage"
            :items-per-page="currentPerPage"
            @click:row="rowClickHandler"
            :loading="isRequestWaiting"
            :options.sync="options"
            :server-items-length="totalItems"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems,
            }"
            show-select
            @pagination="setPage"
          >
            <template v-slot:header.open="{}">
              <thead>
                <tr>
                  <th>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          style="margin-left: 6px"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </template>
                      <span> Открыть документ в новой вкладке. </span>
                    </v-tooltip>
                  </th>
                </tr>
              </thead>
            </template>
            <template v-slot:item.payRequestStatusId="{ item }">
              {{ ` ${formatPayRequestStatus(item.payRequestStatusId)}` }}
            </template>
            <template v-slot:item.open="{ item }">
              <v-btn
                @click.stop="openDocumentHandler(item.formUrl)"
                color="primary"
                icon
              >
                <v-icon>mdi-open-in-new</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
    <EditPayRequestStatusPopUp
      :isDeposit="true"
      :isOpened="isEditPopUpOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @refresh="getRequestsFromApi"
      @close="closeEditPopUp"
    ></EditPayRequestStatusPopUp>
  </v-row>
</template>

<script>
// import BigNumber from "bignumber.js";
// import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
// import DataTableTop from "../../shared/DataTableTop.vue";
import {
  GET_PAY_REQUESTS_URL,
  PATCH_PAY_REQUESTS_URL,
} from "../../../config/http";

import EditPayRequestStatusPopUp from "../popUps/EditPayRequestStatusPopUp.vue";
import DataTableMixin from "../../../mixins/DataTableMixin";
import RoleMixin from "../../../mixins/RoleMixin";

export default {
  name: "DepositRequestTab",
  mixins: [DataTableMixin, RoleMixin],
  data: () => ({
    headers: [
      { text: "", value: "icon", sortable: false, width: 36 },
      { text: "Email", value: "User.email", sortable: true },
      { text: "Имя", value: "User.firstName", sortable: true },
      { text: "Фамилия", value: "User.lastName", sortable: true },
      { text: "Статус", value: "payRequestStatusId", sortable: true },
      { text: "", value: "open", sortable: false, width: 36 },
    ],
    test: [
      {
        id: 3,
        formUrl: "https://example.com/pdf",
        isBalanceChangedByDepositApprove: false,
        createdAt: "2022-09-29T13:55:08.187Z",
        updatedAt: "2022-09-29T13:58:21.273Z",
        betatransferDepositId: 1,
        paylamaDepositId: null,
        payRequestStatusId: 1,
        payRequestTypeId: 1,
        userId: 6,
        User: {
          id: 6,
          email: "user6@user.com",
          phone: "123123123",
          firstName: "First",
          lastName: "Last",
          country: "RUS",
          ipAddress: "127.0.0.1",
          balance: 0,
          lockedBalance: 0,
          equity: 0,
          credit: 0,
          margin: 0,
          isFirstTimeAuthorized: true,
          isRestoringProcess: false,
          isEmailVerified: false,
          isFirstDeposit: false,
          isFirstCall: false,
          firstCallDate: null,
          lastLoginDate: "2022-09-29T13:58:41.422Z",
          documents: [],
          firstTimeDepositAmount: null,
          firstTimeDepositDate: null,
          depositsSum: 0,
          depositsCount: 0,
          lastWithdrawDate: null,
          comment: "",
          isOnline: false,
          isOnTrading: false,
          isClient: false,
          utm_source: "",
          utm_medium: "",
          utm_campaing: "",
          utm_content: "",
          additionalPhones: [],
          createdAt: "2022-08-29T14:51:44.602Z",
          updatedAt: "2022-09-29T13:58:41.422Z",
          accountStatusId: 1,
          saleAdminId: null,
          retAdminId: null,
          affManagerAdminId: null,
          callCenterId: 1,
          phoneStatusId: 4,
          processingStatusId: 1,
          verificationStatusId: 1,
        },
      },
    ],
    selectedItems: [],
    isVerificationUserOpened: false,
    isDetailBidsPopUpOpened: false,
    isDetailActionsPopUpOpened: false,
    isEditSeveralPopUpOpened: false,
    isConfirmPopUpOpened: false,
  }),
  methods: {
    getRequestsFromApi() {
      this.isRequestWaiting = true;

      const params = {
        ...this.getQueryParams(),
        // payRequestStatusId: 2,
        payRequestTypeId: 1,
      };

      this.$http
        .get(GET_PAY_REQUESTS_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            const response = data.data;
            this.items = response.items;
            this.totalItems = response.count;

            // this.items = this.test;
            // this.totalItems = 1;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    updateRequestsStatus(id) {
      this.isRequestWaiting = true;

      const body = {
        payRequestStatusId: 2,
      };

      this.$http
        .patch(PATCH_PAY_REQUESTS_URL(id), body)
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            const response = data.data;
            this.items = response.items;
            this.totalItems = response.count;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    rowClickHandler(item) {
      // console.log(item);
      this.openEditPopUp(item);
    },
    formatPayRequestStatus(itemId) {
      // console.log(itemId);
      // console.log(this.getPayRequestStatus);

      let res = "res";

      res = this.getPayRequestStatus.filter((status) => {
        return status.id === itemId;
      });
      // console.log(res);
      return res[0].name;
    },
    openDocumentHandler(link) {
      // console.log(link);
      window.open(link, "_blank").focus();
    },

    watcherHandler() {
      this.getRequestsFromApi();
    },

    setPage(event) {
      this.$store.commit("setDepositRequestsPage", event.page);
      this.$store.commit("setDepositRequestsPerPage", event.itemsPerPage);
    },
    search(value) {
      value;
      // this.isRequestWaiting = true;

      // const params = {
      //   ...this.getQueryParams(),
      //   search: value.trim(),
      // };

      // this.$http
      //   .get(GET_PAY_REQUESTS_URL(), { params })
      //   .then((response) => {
      //     response.json().then((data) => {
      //       console.log(data);
      //       const response = data.data;
      //       this.items = response.items;
      //       this.totalItems = response.count;
      //       this.isRequestWaiting = false;
      //     });
      //   })
      //   .catch((e) => {
      //     console.error(e.message);
      //     this.isRequestWaiting = false;
      //   });
    },
  },
  computed: {
    getPayRequestStatus() {
      return this.$store.getters.payRequestStatus;
    },
    getPayRequestType() {
      return this.$store.getters.payRequestType;
    },
    // isSeveralItemsSelected() {
    //   return this.selectedItems.length > 0;
    // },
    currentPage() {
      return this.$store.getters.DepositRequestsPage;
    },
    currentPerPage() {
      return this.$store.getters.DepositRequestsPerPage;
    },
  },
  components: {
    EditPayRequestStatusPopUp,
  },
};
</script>

<style></style>
