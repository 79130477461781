<template>
  <v-row class="pa-10" no-gutters>
    <v-col>
      <v-row>
        <v-col>
            <v-tabs
                v-model="selectedTab"
                slider-color="primary"
                :show-arrows="true"
            >
              <v-tab v-for="(tab, index) in tabs" :key="index">
                {{ tab.name }}
              </v-tab>
            </v-tabs>
        </v-col>
      </v-row>
      <v-card class="pa-5" elevation="4">
        <v-tabs-items touchless v-model="selectedTab" :value="selectedTab">
          <v-tab-item v-for="item in tabs" :key="item.tab">
            <v-card flat>
              <component :is="item.component"></component>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ProfilesMainTab from "../profilesPage/tabs/ProfilesMainTab.vue";
import ProfilesVerificationTab from "../profilesPage/tabs/ProfilesVerificationTab.vue";
import DefaultLeveragesTab from "../profilesPage/tabs/DefaultLeveragesTab.vue";
import LoadUsersTab from "../profilesPage/tabs/LoadUsersTab.vue";
import DepositRequestsTab from "../profilesPage/tabs/DepositRequestsTab.vue";
import WithdrawRequestTab from "../profilesPage/tabs/WithdrawRequestTab.vue";
import SettingsTab from "../profilesPage/tabs/SettingsTab.vue";

import RoleMixin from "../../mixins/RoleMixin";

export default {
  name: "ProfilesPage",
  mixins: [RoleMixin],
  data: () => ({
    selectedTab: 0,
    isCreateUserOpened: false,
  }),
  methods: {},
  computed: {
    tabs() {
      if (this.isSuperSuperAdminPermission)
        return [
          {
            name: "Главная",
            component: ProfilesMainTab,
          },
          {
            name: "На верификацию",
            component: ProfilesVerificationTab,
          },
          {
            name: "Дефолтные плечи",
            component: DefaultLeveragesTab,
          },
          {
            name: "Запросы на пополнение",
            component: DepositRequestsTab,
          },
          {
            name: "Запросы на вывод",
            component: WithdrawRequestTab,
          },
          {
            name: "Загрузка пользователей",
            component: LoadUsersTab,
          },
          {
            name: "Настройки",
            component: SettingsTab,
          },
        ];
      else
        return [
          {
            name: "Главная",
            component: ProfilesMainTab,
          },
          {
            name: "На верификацию",
            component: ProfilesVerificationTab,
          },
          {
            name: "Загрузка пользователей",
            component: LoadUsersTab,
          },
        ];
    },
  },
  components: {
    ProfilesMainTab,
    ProfilesVerificationTab,
    DefaultLeveragesTab,
    DepositRequestsTab,
    WithdrawRequestTab,
  },
};
</script>

<style></style>
