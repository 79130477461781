import RoleMixin from "./RoleMixin";

export default {
  name: "StatisticsMixin",
  mixins: [RoleMixin],
  data: () => ({}),
  methods: {
    getMyStatistics() {
      if (this.iAmAdvertisingManager) {
        return {
          usersCount: {
            name: "Количество приведенных клиентов",
            icon: "mdi-account-group",
          },
          processedUsersCount: {
            name: "Количество обработанных клиентов",
            icon: "mdi-account-multiple-check",
          },
          unprocessedUsersCount: {
            name: "Количество необработанных клиентов",
            icon: "mdi-account-multiple-remove",
          },
          usersFirstDepositsSum: {
            name: "Сумма первых депозитов всех клиентов",
            icon: "mdi-cash-fast",
          },
          usersAllDepositsSum: {
            name: "Сумма всех депозитов клиентов",
            icon: "mdi-cash-multiple",
          },
        };
      } else if (this.iAmClientManager) {
        return {
          calledCount: {
            name: "Количество обзвоненных пользователей",
            icon: "mdi-account-group",
          },
          callLaterCount: {
            name: 'Количество аккаунтов "позвонить позже"',
            icon: "mdi-phone-refresh",
          },
          unprocessedUsersCount: {
            name: "Количество необработанных клиентов",
            icon: "mdi-account-multiple-remove",
          },
          usersFirstDepositsSum: {
            name: "Сумма первых депозитов обработанных пользователей",
            icon: "mdi-cash-fast",
          },
        };
      } else {
        return {
          usersCount: {
            name: "Количество клиентов",
            icon: "mdi-account-group",
          },
          processedUsersCount: {
            name: "Количество обработанных клиентов",
            icon: "mdi-account-multiple-check",
          },
          unprocessedUsersCount: {
            name: "Количество необработанных клиентов",
            icon: "mdi-account-multiple-remove",
          },
          usersFirstDepositsSum: {
            name: "Сумма первых депозитов всех клиентов",
            icon: "mdi-cash-fast",
          },
          usersAllDepositsSum: {
            name: "Сумма всех депозитов клиентов",
            icon: "mdi-cash-multiple",
          },
        };
      }
    },
    getStatisticsByRole(roleId) {
      if (roleId === this.getSuperAdminRole.id) {
        return {
          usersCount: {
            name: "Количество приведенных клиентов",
            icon: "mdi-account-group",
          },
          processedUsersCount: {
            name: "Количество обработанных клиентов",
            icon: "mdi-account-multiple-check",
          },
          unprocessedUsersCount: {
            name: "Количество необработанных клиентов",
            icon: "mdi-account-multiple-remove",
          },
          usersFirstDepositsSum: {
            name: "Сумма первых депозитов всех клиентов",
            icon: "mdi-cash-fast",
          },
          usersAllDepositsSum: {
            name: "Сумма всех депозитов клиентов",
            icon: "mdi-cash-multiple",
          },
        };
      } else if (roleId === this.getSuperAdminRole.id) {
        return {
          calledCount: {
            name: "Количество обзвоненных пользователей",
            icon: "mdi-account-group",
          },
          callLaterCount: {
            name: 'Количество аккаунтов "позвонить позже"',
            icon: "mdi-phone-refresh",
          },
          unprocessedUsersCount: {
            name: "Количество необработанных клиентов",
            icon: "mdi-account-multiple-remove",
          },
          usersFirstDepositsSum: {
            name: "Сумма первых депозитов обработанных пользователей",
            icon: "mdi-cash-fast",
          },
        };
      } else {
        return {
          usersCount: {
            name: "Количество клиентов",
            icon: "mdi-account-group",
          },
          processedUsersCount: {
            name: "Количество обработанных клиентов",
            icon: "mdi-account-multiple-check",
          },
          unprocessedUsersCount: {
            name: "Количество необработанных клиентов",
            icon: "mdi-account-multiple-remove",
          },
          usersFirstDepositsSum: {
            name: "Сумма первых депозитов всех клиентов",
            icon: "mdi-cash-fast",
          },
          usersAllDepositsSum: {
            name: "Сумма всех депозитов клиентов",
            icon: "mdi-cash-multiple",
          },
        };
      }
    },
  },
};
