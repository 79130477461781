<template>
  <v-card class="pa-5 mt-12" elevation="1">
    <v-data-table
      :headers="headers"
      :items="comments"
      item-key="id"
      sort-by="createdAt"
      show-group-by
    >
      <template v-slot:top>
        <v-row align="center">
          <v-col cols="9">
            <v-text-field
              label="Comments input"
              v-model="currentComment"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn
              elevation="2"
              tile
              :disabled="currentComment.length < 1"
              @click="addComment"
            >
              send</v-btn
            >
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.delete="{ item }">
        <v-btn
          v-if="isSuperAdminPermission || iAmCRMManager"
          class="white--text"
          @click.stop="openDeletePopUp(item)"
          color="red"
          icon
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <ConfirmPopUp
      :question="'Вы действительно хотите удалить этот комментарий?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deleteComment"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-card>
</template>

<script>
import RoleMixin from "../../mixins/RoleMixin";
import ConfirmPopUp from "../shared/ConfirmPopUp.vue";
import moment from "moment";

import { GET_ADD_COMMENT_URL, GET_DELETE_COMMENT_URL } from "../../config/http";

export default {
  name: "DetailUserPopUp",
  mixins: [RoleMixin],
  props: {
    itemCopy: Object || null || undefined,
    CommentHistory: Array || null || undefined,
  },
  data: () => ({
    phoneStatus: null,
    isHistoryBalancePopUpOpened: false,
    isHistoryProcessPopUpOpened: false,
    isDeletePopUpOpened: false,
    selectedItem: null,
    currentComment: "",
    headers: [
      {
        text: "Name",
        align: "start",
        value: "name",
        width: "20px",
      },
      {
        text: "Date",
        value: "createdAt",
        align: "start",
        width: "20px",
      },
      {
        text: "Comment",
        value: "comment",
        align: "start",
        groupable: false,
        width: "200px",
      },
      {
        text: "",
        value: "delete",
        sortable: false,
        groupable: false,
        width: "20px",
      },
    ],
  }),
  methods: {
    addComment() {
      this.isRequestWaiting = true;
      const body = {
        comment: this.currentComment,
      };
      this.$http
        .post(GET_ADD_COMMENT_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then(() => {
            // console.log(response);
            this.currentComment = "";

            this.$emit("update");
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    deleteComment() {
      // console.log(this.CommentHistory);
      this.isRequestWaiting = true;

      const commentId = this.selectedItem.id;
      this.$http
        .delete(GET_DELETE_COMMENT_URL(this.itemCopy.id, commentId))
        .then((response) => {
          response.json().then(() => {
            // console.log(response);
            this.isDeletePopUpOpened = false;
            this.$emit("update");
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    openDeletePopUp(item) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },

    formatCommentsArray(arr) {
      let array = [];
      arr.map((item) => {
        array.push({
          id: item.id,
          userId: item.userId,
          createdAt: moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          comment: item.comment,
          name: `${item.Admin.firstName} ${item.Admin.lastName}`,
        });
      });
      return array;
    },
  },
  computed: {
    iCanSignInByUser() {
      if (
        this.iAmSuperAdmin ||
        this.iAmAdmin ||
        this.iAmRetTeam ||
        this.iAmRet ||
        this.iAmRetHead
      ) {
        return true;
      }
      return false;
    },
    comments() {
      return this.formatCommentsArray(this.CommentHistory);
    },
  },
  components: {
    ConfirmPopUp,
  },
};
</script>

<style scoped></style>
