<template>
  <v-dialog v-model="isOpened" max-width="600" @click:outside="closePopUp" persistent>
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Верифицировать аккаунт </span>
        </v-col>
      </v-row>
      <v-row v-if="itemCopy && itemCopy.documents.length > 0">
        <v-col align="end">
          <v-btn color="primary" small>Скачать все документы</v-btn>
        </v-col>
      </v-row>
      <v-row
        v-if="itemCopy && itemCopy.documents.length > 0"
        class="mb-4"
        align="center"
        justify="space-between"
      >
        <v-col
          v-for="(document, index) in itemCopy.documents"
          :key="index"
          cols="4"
          align="center"
        >
          <DocumentCard @onImageClick="openPreviewPhoto($event)" :documentUrl="document"></DocumentCard>
        </v-col>
      </v-row>
      <v-row v-else class="mb-4" justify="center">
        <v-col align="center">
          <span class="text-h5">Документов не найдено</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col class="pa-1" cols="6">
          <v-btn
            color="primary"
            @click="submitAction(true)"
            :loading="isRequestWaiting"
            block
          >
            Подтвердить
          </v-btn>
        </v-col>
        <v-col class="pa-1" cols="6">
          <v-btn
            color="error"
            @click="submitAction(false)"
            :loading="isRequestWaiting"
            block
          >
            Отклонить
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <PreviewPhotoPopUp
      :isOpened="isPreviewPhotoOpened"
      :photoUrl="selectedImage"
      @close="closePreviewPhoto"
    ></PreviewPhotoPopUp>
  </v-dialog>
</template>

<script>
import { GET_UPDATE_USER_URL } from "../../../config/http";
import PopUpMixin from "../../../mixins/PopUpMixin";
import PreviewPhotoPopUp from "../../shared/PreviewPhotoPopUp.vue";
import DocumentCard from "../cards/DocumentCard.vue";

export default {
  name: "VerificationUserPopUp",
  mixins: [PopUpMixin],
  data: () => ({
    isPreviewPhotoOpened: false,
    selectedImage: null,
  }),
  methods: {
    submitAction(isAccepted) {
      this.isRequestWaiting = true;

      const statusId = isAccepted
        ? this.getVerifiedStatus
        : this.getRejectStatus;

      const body = {
        verificationStatusId: statusId,
      };

      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.itemCopy.verificationStatusId = statusId;
            this.isRequestWaiting = false;
            this.$emit("update", this.itemCopy);
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    openPreviewPhoto(url) {
      this.selectedImage = url;
      this.isPreviewPhotoOpened = true;
    },
    closePreviewPhoto() {
      this.isPreviewPhotoOpened = false;
      this.selectedImage = null;
    },
  },
  computed: {
    getVerifiedStatus() {
      return this.$store.getters.verifiedStatus.id;
    },
    getRejectStatus() {
      return this.$store.getters.rejectStatus.id;
    },
  },
  components: {
    PreviewPhotoPopUp,
    DocumentCard,
  },
};
</script>

<style scoped></style>
