<template>
  <v-dialog
    v-model="isOpened"
    max-width="100vw"
    :width="widthImg !== null ? widthImg : ''"
    :height="heightImg !== null ? heightImg : ''"
    @click:outside="closePopUp"
  >
    <v-row
      v-if="heightImg !== null && widthImg !== null"
      align="center"
      no-gutters
    >
      <v-col align="center">
        <v-img
          class="image-preview"
          min-height="200"
          min-width="200"
          :width="widthImg !== null ? widthImg : ''"
          :height="heightImg !== null ? heightImg : ''"
          :src="photoUrl"
        >
          <v-row style="height: 0px" no-gutters align="center">
            <v-col align="end">
              <v-btn
                class="close-button"
                min-width="0"
                @click="closePopUp"
                :width="29"
                :height="29"
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-img>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: "PreviewPhotoPopUp",
  data: () => ({
    widthImg: null,
    heightImg: null,
  }),
  methods: {
    closePopUp() {
      console.log("Close preview!");
      this.$emit("close");
      this.widthImg = null;
      this.height = null;
    },
    getContainSize(originalSize, newSize) {
      var ratio = originalSize[0] / originalSize[1];

      var maximizedToWidth = [newSize[0], newSize[0] / ratio];
      var maximizedToHeight = [newSize[1] * ratio, newSize[1]];

      if (maximizedToWidth[1] > newSize[1]) {
        return maximizedToHeight;
      } else {
        return maximizedToWidth;
      }
    },
  },
  props: {
    isOpened: Boolean,
    photoUrl: String,
  },
  watch: {
    isOpened(isOpen) {
      if (isOpen) {
        const url = this.photoUrl;
        console.log("URL:");
        console.log(url);
        if (url !== null) {
          const img = new Image();
          const callback = (width, height) => {
            const newSizes = this.getContainSize(
              [width, height],
              [window.innerWidth, (window.innerHeight / 100) * 80]
            );
            this.widthImg = newSizes[0];
            this.heightImg = newSizes[1];
            console.log("Width: " + newSizes[0]);
            console.log("Height: " + newSizes[1]);
          };
          img.onload = function () {
            console.log(`${this.width}x${this.height}`);
            callback(this.width, this.height);
          };
          img.src = url;
        }
      }
    },
  },
};
</script>

<style scoped>
.pop-up {
  background: transparent;
}
.close-button {
  z-index: 500;
  background: white;
}
</style>
