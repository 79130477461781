<template>
  <v-row no-gutters>
    <v-col>
      <v-row v-if="false">
        <v-col align="end">
          <v-btn @click="isCreateUserOpened = true" color="primary">
            Создать профиль
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" align="left">
          <v-row>
            <v-col cols="12">
              <input
                ref="fileInput"
                style="display: none"
                type="file"
                @change="chooseFile"
              />
              <v-btn @click="$refs.fileInput.click()" color="primary">
                {{ this.file ? "Выбрать другой" : "Выбрать файл" }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-btn v-if="file" @click="openSubmit" color="primary">
                Отправить файл
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="9" align="left" v-if="file">
          <v-row>
            <v-col cols="12"> Название: {{ fileName }} </v-col>
            <v-col cols="12"> Тип: {{ fileType }} </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <ConfirmPopUp
      :question="'Вы действительно хотите загрузить этот файл?'"
      :isOpened="isConfirmOpened"
      @yesAction="sendFile"
      @noAction="closeSubmit"
      @close="closeSubmit"
    ></ConfirmPopUp>
  </v-row>
</template>

<script>
import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";
import { GET_BULK_LOAD_URL } from "../../../config/http";

import DataTableMixin from "../../../mixins/DataTableMixin";
import RoleMixin from "../../../mixins/RoleMixin";

export default {
  name: "LoadUsersTab",
  mixins: [DataTableMixin, RoleMixin],
  data: () => ({
    file: null,
    isConfirmOpened: null,
  }),
  methods: {
    chooseFile(e) {
      try {
        const file = e.target.files[0];
        file ? (this.file = file) : "";
      } catch (e) {
        console.warn(e);
      }
    },
    sendFile() {
      this.isConfirmOpened = false;
      let formData = new FormData();
      formData.append("file", this.file);
      // fetch(GET_BULK_LOAD_URL(), { method: "POST", body: formData });

      this.$http
        .post(GET_BULK_LOAD_URL(), formData)
        .then((response) => {
          response.json().then(() => {
            // console.log(data);
            this.file = null;
            this.$store.commit("alert/setAlert", {
              message: "Данные загружены",
              color: "green",
              icon: "account",
            });
          });
        })
        .catch((e) => {
          console.error(e);
          this.$store.commit("alert/setAlert", {
            message: `${e.body.error.substring(46)}`,
            color: "red",
            icon: "account",
          });
          this.isRequestWaiting = false;
        });
    },
    openSubmit() {
      this.isConfirmOpened = true;
    },
    closeSubmit() {
      this.isConfirmOpened = false;
    },
  },
  computed: {
    fileName() {
      return this.file ? this.file.name : "";
    },
    fileType() {
      const str = this.fileName;
      const index = str.lastIndexOf(".");

      if (this.file) {
        if (this.file.type) return this.file.type;
        else return this.fileName.slice(index + 1);
      } else return "";
    },
  },
  components: {
    ConfirmPopUp,
  },
};
</script>

<style></style>
