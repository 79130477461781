<template>
  <v-dialog
    v-model="isOpened"
    :min-width="!$vuetify.breakpoint.xs ? '300' : '150'"
    :max-width="!$vuetify.breakpoint.xs ? '25%' : ''"
    @click:outside="noClickHandler"
    persistent
  >
    <v-card class="pa-1 pa-sm-3 pa-md-3 pa-lg-3 pa-xl-3">
      <v-card-title class="pop-up-text headline mb-5">
        {{ question }}
      </v-card-title>

      <v-row no-gutters>
        <v-col class="pa-1" cols="6">
          <v-btn
            color="primary"
            @click="yesClickHandler"
            :loading="isRequestWaiting"
            block
          >
            Yes
          </v-btn>
        </v-col>
        <v-col class="pa-1" cols="6">
          <v-btn
            color="primary"
            @click="noClickHandler"
            :loading="isRequestWaiting"
            block
          >
            No
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmPopUp",
  data: () => ({
    isRequestWaiting: false,
  }),
  methods: {
    yesClickHandler() {
      this.isRequestWaiting = true;
      this.$emit("yesAction");
    },
    noClickHandler() {
      this.isRequestWaiting = true;
      this.$emit("noAction");
    },
  },
  watch: {
    isOpened() {
      this.isRequestWaiting = false;
    },
  },
  props: {
    isOpened: Boolean,
    question: String,
  },
};
</script>

<style scoped>
.pop-up-text {
  white-space: normal !important;
  word-wrap: break-word !important;
  word-break: normal !important;
}
</style>
