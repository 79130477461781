<template>
  <v-dialog
    v-model="isOpened"
    max-width="400"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6">
            Вы собираетесь изменить статус запроса на
            {{ isDeposit ? "пополнение" : "вывод" }}. Текущий статус:
            {{ getStatus }}
          </span>
        </v-col>
      </v-row>
      <v-form
        v-if="itemCopy"
        v-model="isValid"
        @submit.prevent="submitHandler"
        ref="form"
      >
        <v-row no-gutters>
          <v-col>
            <v-select
              v-model="selectedStatus"
              :items="getPayRequestStatus"
              item-text="name"
              item-value="id"
              label="Новый статус"
              persistent-hint
              return-object
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-2" no-gutters>
          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
              :disabled="!selectedStatus"
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";

import { GET_DEFAULT_LEVERAGE_URL } from "../../../config/http";

export default {
  name: "EditPayRequestStatusPopUp",
  mixins: [PopUpMixin, RoleMixin],
  props: {
    isDeposit: Boolean,
  },
  data: () => ({
    selectedStatus: null,
    items: [],
    errorCode: null,
  }),
  methods: {
    submitAction() {
      this.isRequestWaiting = true;
      const body = {
        payRequestStatusId: this.selectedStatus.id,
      };
      this.$http
        .patch(GET_DEFAULT_LEVERAGE_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then(() => {
            // console.log(response);
            this.$store.commit("alert/setAlert", {
              message: "Статус изменен",
              color: "green",
              icon: "list-status",
            });

            this.isRequestWaiting = false;
            this.$emit("refresh");
            this.$emit("close");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.$store.commit("alert/setAlert", {
            message: "Ошибка",
            color: "red",
            icon: "list-status",
          });

          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.$nextTick(() => {
          this.$refs.form.reset();
        });
      }
    },
    formatPayRequestStatus(itemId) {
      const res = this.getPayRequestStatus.filter((status) => {
        return status.id === itemId;
      });
      return res[0].name;
    },
  },
  computed: {
    getPayRequestStatus() {
      return this.$store.getters.payRequestStatus;
    },
    getStatus() {
      if (this.itemCopy)
        return this.formatPayRequestStatus(this.itemCopy.payRequestStatusId);
      else return "";
    },
  },
};
</script>

<style scoped></style>
