export default {
  name: "RoleMixin",
  data: () => ({}),
  methods: {},
  computed: {
    getMyRole() {
      const role = this.$store.getters.roles.find((item) => {
        return item.id === this.$store.getters.roleId;
      });
      return role;
    },
    // isSuperAdminPermission() {
    //   return this.getMyRole.level >= this.$store.getters.roleSuperAdmin.level;
    // },
    // isAdminPermission() {
    //   return this.getMyRole.level >= this.$store.getters.roleSuperSuperAdmin.level;
    // },
    // isClientManagerPermission() {
    //   return (
    //     this.getMyRole.level >= this.$store.getters.roleClientsManager.level
    //   );
    // },
    // isFinancialManagerPermission() {
    //   return (
    //     this.getMyRole.level >= this.$store.getters.roleSuperSuperAdmin.level
    //   );
    // },
    // isAdvertisingManagerPermission() {
    //   return (
    //     this.getMyRole.level >= this.$store.getters.roleAdvertisingManager.level
    //   );
    // },
    // isTeamAdminPermission() {
    //   return this.getMyRole.level >= this.$store.getters.roleTeamAdmin.level;
    // },

    isSuperAdminPermission() {
      return this.getMyRole.level >= this.$store.getters.roleSuperAdmin.level;
    },
    isCRMManagerPermission() {
      return this.getMyRole.level >= this.$store.getters.roleCRMManager.level;
    },
    isSalePermission() {
      return this.getMyRole.level >= this.$store.getters.roleSale.level;
    },
    isRetPermission() {
      return this.getMyRole.level >= this.$store.getters.roleRet.level;
    },
    isSupportPermission() {
      return this.getMyRole.level >= this.$store.getters.roleSupport.level;
    },
    isAffManagerPermission() {
      return this.getMyRole.level >= this.$store.getters.roleAffManager.level;
    },
    isAffTeamPermission() {
      return this.getMyRole.level >= this.$store.getters.roleAffTeam.level;
    },
    isRetHeadPermission() {
      return this.getMyRole.level >= this.$store.getters.roleRetHead.level;
    },
    isRetTeamPermission() {
      return this.getMyRole.level >= this.$store.getters.roleRetTeam.level;
    },
    isSaleTeamPermission() {
      return this.getMyRole.level >= this.$store.getters.roleSaleTeam.level;
    },
    isSaleHeadPermission() {
      return this.getMyRole.level >= this.$store.getters.roleSaleHead.level;
    },
    isSuperSuperAdminPermission() {
      return (
        this.getMyRole.level >= this.$store.getters.roleSuperSuperAdmin.level
      );
    },
    // iAmAdmin() {
    //   return (
    //     this.$store.getters.roleId ===
    //     this.$store.getters.roleAdminCallCenter.id
    //   );
    // },
    iAmSuperAdmin() {
      return (
        this.$store.getters.roleId === this.$store.getters.roleSuperAdmin.id
      );
    },
    iAmCRMManager() {
      return (
        this.$store.getters.roleId === this.$store.getters.roleCRMManager.id
      );
    },
    iAmSale() {
      return this.$store.getters.roleId === this.$store.getters.roleSale.id;
    },
    iAmRet() {
      return this.$store.getters.roleId === this.$store.getters.roleRet.id;
    },
    iAmSupport() {
      return this.$store.getters.roleId === this.$store.getters.roleSupport.id;
    },
    iAmAffManager() {
      return (
        this.$store.getters.roleId === this.$store.getters.roleAffManager.id
      );
    },
    iAmAffTeam() {
      return this.$store.getters.roleId === this.$store.getters.roleAffTeam.id;
    },
    iAmRetHead() {
      return this.$store.getters.roleId === this.$store.getters.roleRetHead.id;
    },
    iAmRetTeam() {
      return this.$store.getters.roleId === this.$store.getters.roleRetTeam.id;
    },
    iAmSaleTeam() {
      return this.$store.getters.roleId === this.$store.getters.roleSaleTeam.id;
    },
    iAmSaleHead() {
      return this.$store.getters.roleId === this.$store.getters.roleSaleHead.id;
    },
    iAmSuperSuperAdmin() {
      return (
        this.$store.getters.roleId ===
        this.$store.getters.roleSuperSuperAdmin.id
      );
    },

    // iAmSuperAdmin() {
    //   return (
    //     this.$store.getters.roleId === this.$store.getters.roleSuperAdmin.id
    //   );
    // },
    // iAmAdmin() {
    //   return (
    //     this.$store.getters.roleId ===
    //     this.$store.getters.roleAdminCallCenter.id
    //   );
    // },
    // iAmClientManager() {
    //   return (
    //     this.$store.getters.roleId === this.$store.getters.roleClientsManager.id
    //   );
    // },
    // iAmFinancialManager() {
    //   return (
    //     this.$store.getters.roleId ===
    //     this.$store.getters.roleFinancialManager.id
    //   );
    // },
    // iAmAdvertisingManager() {
    //   return (
    //     this.$store.getters.roleId ===
    //     this.$store.getters.roleAdvertisingManager.id
    //   );
    // },
    // iAmTeamAdmin() {
    //   return (
    //     this.$store.getters.roleId === this.$store.getters.roleTeamAdmin.id
    //   );
    // },

    getSuperAdminRole() {
      return this.$store.getters.roleSuperAdmin;
    },
    getCRMManagerRole() {
      return this.$store.getters.roleCRMManager;
    },
    getSaleRole() {
      return this.$store.getters.roleSale;
    },
    getRetRole() {
      return this.$store.getters.roleRet;
    },
    getSupportRole() {
      return this.$store.getters.roleSupport;
    },
    getAffManagerRole() {
      return this.$store.getters.roleAffManager;
    },
    getAffTeamRole() {
      return this.$store.getters.roleAffTeam;
    },
    getRetHeadRole() {
      return this.$store.getters.roleRetHead;
    },
    getRetTeamRole() {
      return this.$store.getters.roleRetTeam;
    },
    getSaleTeamRole() {
      return this.$store.getters.roleSaleTeam;
    },
    getSaleHeadRole() {
      return this.$store.getters.roleSaleHead;
    },
    getSuperSuperAdminRole() {
      return this.$store.getters.roleSuperSuperAdmin;
    },

    // getSuperAdminRole() {
    //   return this.$store.getters.roleSuperAdmin;
    // },
    // getAdminRole() {
    //   return this.$store.getters.roleAdminCallCenter;
    // },
    // getClientManagerRole() {
    //   return this.$store.getters.roleSuperSuperAdmin;
    // },
    // getFinancialManagerRole() {
      // return this.$store.getters.roleSuperSuperAdmin;
    // },
    // getAdvertisingManagerRole() {
    //   return this.$store.getters.roleSuperSuperAdmin;
    // },
    // getTeamAdminRole() {
    //   return this.$store.getters.roleSuperSuperAdmin;
    // },

    getMyCallCenterId() {
      return this.$store.getters.callCenterId;
    },
    getEqualOrLowerLevelRoles() {
      return this.$store.getters.roles.filter((item) => {
        return item.level <= this.getMyRole.level;
      });
    },
    getLowerLevelRoles() {
      const lowerLevel = this.$store.getters.roles.filter((item) => {
        return item.level < this.getMyRole.level;
      });
      return lowerLevel;
    },
  },
};
