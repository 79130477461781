<template>
  <v-dialog
    v-model="isOpened"
    max-width="1000"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-2" no-gutters>
        <v-col align="center">
          <span class="text-h6 font-weight-bold">
            Информация действий пользователя
          </span>
        </v-col>
      </v-row>
      <v-row v-if="itemCopy" class="bids-table-container" no-gutters>
        <v-col>
          <v-data-table
            :headers="headers"
            :items="actions"
            :options.sync="options"
            :loading="isRequestWaiting"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ getFormatDateTime(item.createdAt) }}
            </template>
            <template v-slot:item.value="{ item }">
              <span v-if="item.actionTypeId === 3">
                {{ `Цена: ${item.value.price};` }}
                {{ `Предсказание: ${item.value.predict ? "Выше" : "Ниже"};` }}
                {{ `Количество: ${item.value.count};` }}
                {{ `Множитель: ${item.value.multiplier};` }}
                {{ `Единицы измерения: ${item.value.unitsInLot}` }}
              </span>
              <span v-else-if="item.actionTypeId === 4">
                {{ `${item.value.isWin ? "+" : ""} ${item.value.profit}` }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import BigNumber from "bignumber.js";
import moment from "moment";

import { GET_ACTIONS_USER_URL } from "../../../config/http";

export default {
  name: "DetailActionsPopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    options: {},
    actions: [],
    headers: [
      { text: "Действие", value: "ActionType.name", sortable: true },
      { text: "Результат", value: "value", sortable: true },
      { text: "Дата", value: "createdAt", sortable: true },
    ],
  }),
  methods: {
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.actions = [];
        this.getActionsFromApi();
      }
    },
    getActionsFromApi() {
      this.isRequestWaiting = true;

      // const params = {
      //   sortBy: "createdAt",
      //   sortType: "desc",
      //   // limit: itemsPerPage,
      // };

      let params = this.getQueryParams();

      this.$http
        .get(GET_ACTIONS_USER_URL(this.itemCopy.id), { params })
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            this.actions = response.data.items;
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    getQueryParams() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      //console.log(page);
      //console.log(itemsPerPage);

      const skipCount = (page - 1) * itemsPerPage;

      const params = {
        skip: skipCount > 0 ? skipCount : 0,
        limit: itemsPerPage,
        // sortBy: sortBy[0],
        // sortType: sortDesc[0] ? "asc" : "desc",
      };

      if (sortBy) params["sortBy"] = sortBy[0];
      if (sortDesc) params["sortType"] = sortDesc[0] ? "asc" : "desc";

      // console.log(sortBy);

      // console.log("Filter Params");
      // console.log(params);

      return params;
    },
    getFormatDateTime(dateTime) {
      return moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  watch: {
    options: {
      handler() {
        this.getActionsFromApi();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
