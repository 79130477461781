/*eslint-disable*/
var appUIService = (function () {
  var instance;

  var createInstance = function () {
    return {
      userAddedCallback: (user) => {},
      employeeAddedCallback: (employee) => {},
      teamAddedCallback: (team) => {},
    };
  };

  return {
    getInstance: function () {
      return instance || (instance = createInstance());
    },
    reCreateInstance: function () {
      return (instance = createInstance());
    },
  };
})();

export default appUIService;