<template>
  <!-- <v-row class="pt-0" no-gutters>
      <span class="font-weight-bold"> Reminder: </span>
    </v-row> -->

  <!-- <v-btn @click="alert = !alert">Open Alert</v-btn>
    <v-alert
      :value="alert"
      border="left"
      color="primary"
      colored-border
      dismissible
      elevation="3"
      icon="mdi-phone"
      prominent
      type="info"
      :transition="
        alert ? 'scroll-x-reverse-transition' : 'scroll-x-transition'
      "
    >
      {{ formatReminderText }}
    </v-alert>

    <v-btn dark @click="snackbar = true"> Open Reminder </v-btn>
    <v-snackbar v-model="snackbar" right top app outlined color="primary">
      {{ formatReminderText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar> -->

  <!-- <v-btn dark @click="openC"> Open C </v-btn>
    <v-alert
      class="text-center"
      :value="!!alert['message']"
      :type="type"
      dismissible
      outlined
      border="left"
      @input="closeAlert"
      >{{ alert["message"] }}</v-alert
    > -->

  <!-- <v-snackbar v-model="show" :top="top" :color="color">
      {{ message }}
      <v-btn flat color="accent" @click.native="show = false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar> -->
  <!-- <v-btn dark @click="setAlert"> Open C </v-btn> -->
  <!-- <v-alert
    class="text-center"
    v-model="show"
    dismissible
    outlined
    border="left"
    @input="show = false"
  >
    {{ message }}
  </v-alert> -->

  <!-- <v-alert
    :value="show"
    @input="show = false"
    border="left"
    color="primary"
    colored-border
    dismissible
    elevation="3"
    icon="mdi-phone"
    prominent
    type="info"
    :transition="show ? 'scroll-x-reverse-transition' : 'scroll-x-transition'"
  >
    {{ formatReminderText }}
  </v-alert> -->

  <v-snackbar
    v-model="show"
    :color="color"
    right
    top
    app
    text
    elevation="2"
    :transition="show ? 'scroll-x-reverse-transition' : 'scroll-x-transition'"
  >
    <v-icon :color="color" large> {{ `mdi-${icon}` }} </v-icon>
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn :color="color" v-bind="attrs" @click="show = false" text>
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import moment from "moment";

export default {
  name: "Reminder",
  data: () => {
    return {
      show: false,
      top: true,
      right: true,
      message: "",
      color: "",
      icon: "",
      timeout: 5000,
    };
  },
  methods: {},
  computed: {
    formatReminderText() {
      return `${moment(new Date()).format("LT")} First Last `;
    },
  },
  created() {
    this.$store.watch(
      (state) => state.alert.alert,
      () => {
        const message = this.$store.state.alert.alert.message;
        if (message) {
          this.show = true;
          this.message = message;
          this.color = this.$store.state.alert.alert.color;
          this.icon = this.$store.state.alert.alert.icon;
          this.$store.commit("alert/setAlert", {});
        }
      }
    );
  },
};
</script>

<style scoped></style>
