export default {
  name: "ProcessingStatusMixin",
  data: () => ({}),
  methods: {
    processingStatusColor(id) {
      let color = "#fff";
      switch (id) {
        case 1:
        case 10:
          //white
          color = "#f3f4f6";
          break;

        case 2:
        case 11:
          //blue light
          color = "#38bdf8";
          break;

        case 3:
        case 12:
          //orange
          color = "#f97316";
          break;

        case 4:
        case 15:
          //green
          color = "#15803d";
          break;

        case 5:
          //green light
          color = "#a3e635";
          break;

        case 6:
        case 13:
          //brown
          color = "#7c2d12";
          break;

        case 7:
        case 18:
          //red
          color = "#e11d48";
          break;

        case 8:
          //pink
          color = "#f472b6";
          break;

        case 9:
        case 20:
          //blue
          color = "#0277BD";
          break;

        case 14:
          //yellow
          color = "#facc15";
          break;

        case 16:
          //violet
          color = "#5b21b6";
          break;

        case 17:
          //chrome
          color = "#90A4AE";
          break;

        case 19:
          //swamp
          color = "#134e4a";
          break;

        case 21:
          color = "#D4E157";
          break;

        case 22:
          color = "#CFD8DC";
          break;

        case 23:
          color = "#10A19D";
          break;
        case 24:
          color = "#540375";
          break;

        case 25:
          color = "#FF7000";
          break;
        case 26:
          color = "#DC3535";
          break;
        case 27:
          color = "#EEEEEE";
          break;
        case 28:
          color = "#DC5F00";
          break;

        case 29:
          color = "#CF0A0A";
          break;
        case 30:
          color = "#000000";
          break;

        case 31:
          color = "#303481";
          break;

        case 32:
          color = "#D6E6F2";
          break;
        case 33:
          color = "#FFF200";
          break;

        default:
          color = "#fff";
          break;
      }
      return color;
    },
  },
};
