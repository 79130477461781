<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="itemCopy" no-gutters>
        <v-col>
          <v-row class="mb-2" no-gutters>
            <v-col>
              <span>
                <span class="font-weight-bold">Команда:</span>
                {{ ` ${itemCopy.name}` }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col cols="12">
              <span class="font-weight-bold"
                >Участники({{ itemCopy.Users.length }}):</span
              >
            </v-col>
          </v-row>
          <v-row
            v-if="itemCopy.Users.length > 0"
            class="teams-members-container mb-2"
            no-gutters
          >
            <v-col
              v-for="(user, index) in itemCopy.Users"
              :key="user.id"
              cols="12"
            >
              <span>
                {{
                  `${index + 1}. ${user.firstName} ${user.lastName} 
                  ${user.email}`
                }}
              </span>
            </v-col>
          </v-row>
          <v-row class="mb-2" no-gutters>
            <v-col cols="12">
              <span class="font-weight-bold">
                Менеджеры({{ itemCopy.teamAdmins.length }}):
              </span>
            </v-col>
          </v-row>
          <v-row
            v-if="itemCopy.teamAdmins.length > 0"
            class="teams-members-container mb-2"
            no-gutters
          >
            <v-col
              v-for="(admin, index) in itemCopy.teamAdmins"
              :key="admin.id"
              cols="12"
            >
              <span>
                {{
                  `${index + 1}. ${admin.firstName} ${admin.lastName} 
                  ${admin.email}`
                }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="statistics" class="mb-2" no-gutters>
            <v-col>
              <v-row
                class="mb-2"
                v-for="(item, index) in statistics"
                :key="index"
                no-gutters
              >
                <v-col>
                  <span>
                    <span class="font-weight-bold">{{ `${item.name}: ` }}</span>
                    {{ item.value }}
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import { GET_TEAM_IN_CALLCENTER_URL } from "../../../config/http";

export default {
  name: "DetailTeamPopUp",
  mixins: [PopUpMixin],
  data: () => ({
    statistics: null,
  }),
  methods: {
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        this.statistics = null;
        this.getTeamStatistics();
      }
    },
    getTeamStatistics() {
      this.$http
        .get(GET_TEAM_IN_CALLCENTER_URL(this.itemCopy.id))
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            const data = response.data.item.statistics;

            const statistics = {
              usersCount: {
                name: "Количество приведенных пользователей",
                icon: "mdi-account-group",
              },
              processedUsersCount: {
                name: "Количество обработанных аккаунтов",
                icon: "mdi-account-multiple-check",
              },
              unprocessedUsersCount: {
                name: "Количество необработанных аккаунтов",
                icon: "mdi-account-multiple-remove",
              },
              usersFirstDepositsSum: {
                name: "Сумма первых депозитов всех пользователей",
                icon: "mdi-cash-fast",
              },
              usersAllDepositsSum: {
                name: "Сумма всех депозитов пользователей",
                icon: "mdi-cash-multiple",
              },
            };

            const cards = [];
            for (var key of Object.keys(statistics)) {
              cards.push({ ...statistics[key], value: data[key] });
            }

            this.statistics = cards;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
  },
  computed: {
    getAdminRoles() {
      return this.$store.getters.roles;
    },
  },
};
</script>

<style scoped>
.teams-members-container {
  max-height: 400px;
  overflow: auto;
}
</style>
