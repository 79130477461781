<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-form v-model="isValid" @submit.prevent="submitHandler" ref="form">
        <v-card class="pa-4 mb-4">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="auto" align="center">
              <span class="text-h6"> Создать Колл-центр </span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="name"
                label="Название"
                placeholder="Название"
                :rules="[...rules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pa-4 mb-4">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="auto" align="center">
              <span class="text-h6"> Создать Администратора Колл-центра </span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="email"
                type="email"
                label="Email"
                placeholder="Email"
                :rules="[...rules.required, ...rules.noSpaces, ...rules.email]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="firstName"
                label="Имя"
                placeholder="Имя"
                :rules="[...rules.required, ...rules.noSpaces]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                v-model="lastName"
                label="Фамилия"
                placeholder="Фамилия"
                :rules="[...rules.required, ...rules.noSpaces]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-row no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Создать
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <SingleActionPopUp
      :buttonText="'Ок'"
      :isOpened="isPasswordOpened"
      :title="newUser ? `Пароль для ${newUser.email}: ${newUser.password}` : ''"
      @click="closePasswordModal"
    ></SingleActionPopUp>
  </v-dialog>
</template>

<script>
import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import {
  GET_INSERT_CALL_CENTER_URL,
  GET_ADD_ADMIN_URL,
} from "../../../config/http";
import SingleActionPopUp from "../../shared/SingleActionPopUp.vue";
import appUIService from "../../../utils/appUIService";

export default {
  name: "CreateCallCenterPopUp",
  mixins: [PopUpMixin, RoleMixin],
  data: () => ({
    name: "",
    email: "",
    firstName: "",
    lastName: "",
    isPasswordOpened: false,
    newUser: null,
  }),
  methods: {
    submitAction() {
      this.createCallCenter();
    },
    createCallCenter() {
      this.isRequestWaiting = true;
      const body = {
        name: this.name,
      };
      this.$http
        .post(GET_INSERT_CALL_CENTER_URL(), body)
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            this.isRequestWaiting = false;
            this.$emit("add", response.data.item);
            this.createAdminCallCenter(response.data.item.id);
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    createAdminCallCenter(callCenterId) {
      const body = {
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        roleId: this.getAdminRole.id,
        callCenterId,
      };
      this.$http
        .post(GET_ADD_ADMIN_URL(), body)
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            appUIService
              .getInstance()
              .employeeAddedCallback(response.data.item);
            this.openPasswordModal(response.data.item);
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    openPasswordModal(user) {
      this.newUser = user;
      this.isPasswordOpened = true;
    },
    closePasswordModal() {
      this.isPasswordOpened = false;
      this.newUser = null;

      this.$emit("close");
    },
  },
  components: {
    SingleActionPopUp,
  },
};
</script>

<style scoped></style>
