<template>
  <v-row class="pa-10">
    <v-col>
      <v-card class="pa-5">
        <v-row v-if="isSuperSuperAdminPermission">
          <v-col align="end">
            <v-btn @click="isCreatePopUpOpened = true" color="primary">
              Создать Колл-центр
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              class="clickable-table"
              :items="items"
              :page="currentPage"
              :items-per-page="currentPerPage"
              @click:row="callCenterClickHandler"
              :loading="isRequestWaiting"
              :options.sync="options"
              :server-items-length="totalItems"
              :footer-props="{
                'items-per-page-options': rowsPerPageItems,
              }"
              show-select
              @pagination="setPage"
            >
              <template v-slot:item.icon="{}">
                <v-icon color="primary">mdi-phone</v-icon>
              </template>
              <template v-slot:item.edit="{ item }">
                <v-btn
                  @click.stop="openEditCallCenter(item)"
                  color="primary"
                  icon
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.delete="{ item }">
                <v-btn
                  @click.stop="openDeletePopUp(item)"
                  class="white--text"
                  color="red"
                  icon
                  v-if="isSuperSuperAdminPermission"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <CreateCallCenterPopUp
      :isOpened="isCreatePopUpOpened"
      @add="addHandler($event)"
      @close="isCreatePopUpOpened = false"
    ></CreateCallCenterPopUp>
    <EditCallCenterPopUp
      :isOpened="isEditPopUpOpened"
      :selectedItem="selectedItem"
      @update="updateHandler($event)"
      @close="closeEditCallCenter"
    ></EditCallCenterPopUp>
    <ConfirmPopUp
      :question="'Вы действительно хотите удалить этот Колл-центр?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deleteCallCenter"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-row>
</template>

<script>
import CreateCallCenterPopUp from "../allCallCentersPage/popUps/CreateCallCenterPopUp.vue";
import EditCallCenterPopUp from "../allCallCentersPage/popUps/EditCallCenterPopUp.vue";
import ConfirmPopUp from "../shared/ConfirmPopUp.vue";
import {
  GET_ALL_CALL_CENTERS_URL,
  GET_DELETE_CALL_CENTER_URL,
} from "../../config/http";

import DataTableMixin from "../../mixins/DataTableMixin";
import RoleMixin from "../../mixins/RoleMixin";

export default {
  name: "AllCallCentersPage",
  mixins: [DataTableMixin, RoleMixin],
  data: () => ({
    headers: [
      { text: "", value: "icon", sortable: false, width: 36 },
      { text: "Название", value: "name", sortable: true },
      { text: "", value: "edit", sortable: false, width: 36 },
      { text: "", value: "delete", sortable: false, width: 36 },
    ],
  }),
  methods: {
    watcherHandler() {
      this.getCallCentersFromApi();
    },
    getCallCentersFromApi() {
      this.isRequestWaiting = true;
      const params = this.getQueryParams();
      this.$http
        .get(GET_ALL_CALL_CENTERS_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.items = data.data.items;
            this.totalItems = data.data.items.length;
            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    callCenterClickHandler(item) {
      // console.log(item);
      this.$router.push({
        name: "CallCenterPage",
        params: {
          callCenterId: item.id,
        },
      });
    },
    openEditCallCenter(callCenter) {
      this.selectedItem = callCenter;
      this.isEditPopUpOpened = true;
    },
    closeEditCallCenter() {
      this.isEditPopUpOpened = false;
      this.selectedItem = null;
    },
    openDeletePopUp(item) {
      this.selectedItem = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedItem = null;
    },
    deleteCallCenter() {
      this.$http
        .delete(GET_DELETE_CALL_CENTER_URL(this.selectedItem.id))
        .then((response) => {
          response.json().then(() => {
            // console.log(response);
            this.deleteHandler();
          });
        })
        .catch((e) => console.error(e));
    },
    setPage(event) {
      this.$store.commit("setAllCallCentersPage", event.page);
      this.$store.commit("setAllCallCentersPerPage", event.itemsPerPage);
    },
  },
  computed: {
    currentPage() {
      return this.$store.getters.AllCallCentersPage;
    },
    currentPerPage() {
      return this.$store.getters.AllCallCentersPerPage;
    },
  },
  components: {
    CreateCallCenterPopUp,
    EditCallCenterPopUp,
    ConfirmPopUp,
  },
};
</script>

<style scoped></style>
