<template>
  <v-dialog
    v-model="isOpened"
    :min-width="!$vuetify.breakpoint.xs ? '400' : '300'"
    :max-width="!$vuetify.breakpoint.xs ? '50%' : ''"
    @click:outside="clickHandler"
    persistent
  >
    <v-card class="pa-1 pa-sm-3 pa-md-3 pa-lg-3 pa-xl-3">
      <v-card-title class="mb-5">
          <span class="title-text">
              {{ title }}
          </span>
        
      </v-card-title>

      <v-row no-gutters>
        <v-col class="pa-1" cols="12">
          <v-btn color="primary" @click="clickHandler" block>
            {{
              buttonText !== null && buttonText !== undefined
                ? buttonText
                : "OK"
            }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SingleActionPopUp",
  data: () => ({}),
  methods: {
    clickHandler() {
      this.$emit("click");
    },
  },
  props: {
    title: String,
    buttonText: String,
    isOpened: Boolean,
  },
};
</script>

<style scoped>
.title-text {
  white-space: normal !important;
  word-wrap: normal !important;
  word-break: normal !important;
}
</style>
