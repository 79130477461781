import Vue from "vue";
import { GET_CONSTANTS_URL } from "../config/http";
import { RemoveAllCookies } from "../utils/cookiesUtility";
import countries from "../config/country";

const getDefaultState = () => {
  return {
    currency: null,
    verificationStatus: null,
    accountStatus: null,
    countries: countries,
    roles: null,
    processingStatus: null,
    phoneStatus: null,
    defaultLeverages: null,
    depositMerchant: null,
    defaultPlatform: null,
    qiwiPayment: null,
    payRequestStatus: null,
    payRequestType: null,
    platforms: null,
  };
};

export default {
  state: getDefaultState(),
  getters: {
    phoneStatus: (state) => state.phoneStatus,
    processingStatus: (state) => state.processingStatus,
    processedStatuses: (state) =>
      state.processingStatus.filter((item) => item.name === "Deposited"),
    notProcessedStatuses: (state) =>
      state.processingStatus.filter((item) => item.name === "Not processed"),
    processedLaterStatuses: (state) =>
      state.processingStatus.filter(
        (item) => item.name !== "Not processed" && item.name !== "Deposited"
      ),
    currency: (state) => state.currency,
    verificationStatus: (state) => state.verificationStatus,
    platforms: (state) => state.platforms,
    defaultLeverages: (state) => state.defaultLeverages,

    depositMerchant: (state) => state.depositMerchant,
    defaultPlatform: (state) => state.defaultPlatform,
    qiwiPayment: (state) => state.qiwiPayment,

    payRequestStatus: (state) => state.payRequestStatus,
    payRequestType: (state) => state.payRequestType,

    accountStatus: (state) => state.accountStatus,
    countries: (state) => state.countries,
    notVerificationStatus: (state) =>
      state.verificationStatus.find((item) => item.name === "Not verified"),
    inProgressVerificationStatus: (state) =>
      state.verificationStatus.find((item) => item.name === "In progress"),
    verifiedStatus: (state) =>
      state.verificationStatus.find((item) => item.name === "Verified"),
    rejectStatus: (state) =>
      state.verificationStatus.find((item) => item.name === "Rejected"),

    roles: (state) => state.roles,

    // roleSuperAdmin: (state) => state.roles.find((item) => item.code === "sa"),
    // roleAdminCallCenter: (state) =>
    //   state.roles.find((item) => item.code === "ca"),
    // roleClientsManager: (state) =>
    //   state.roles.find((item) => item.code === "cm"),
    // roleFinancialManager: (state) =>
    //   state.roles.find((item) => item.code === "fm"),
    // roleAdvertisingManager: (state) =>
    //   state.roles.find((item) => item.code === "am"),
    // roleTeamAdmin: (state) => state.roles.find((item) => item.code === "ta"),

    roleSuperAdmin: (state) => state.roles.find((item) => item.code === "ad"),
    roleCRMManager: (state) => state.roles.find((item) => item.code === "cm"),

    roleSale: (state) => state.roles.find((item) => item.code === "sl"),
    roleRet: (state) => state.roles.find((item) => item.code === "re"),
    roleSupport: (state) => state.roles.find((item) => item.code === "su"),

    roleAffManager: (state) => state.roles.find((item) => item.code === "am"),

    roleAffTeam: (state) => state.roles.find((item) => item.code === "at"),

    roleRetHead: (state) => state.roles.find((item) => item.code === "rh"),
    roleRetTeam: (state) => state.roles.find((item) => item.code === "rt"),

    roleSaleTeam: (state) => state.roles.find((item) => item.code === "st"),

    roleSaleHead: (state) => state.roles.find((item) => item.code === "sh"),

    roleSuperSuperAdmin: (state) =>
      state.roles.find((item) => item.code === "sa"),
  },
  mutations: {
    setPhoneStatus(state, phoneStatus) {
      state.phoneStatus = phoneStatus;
    },
    setCurrency(state, currency) {
      state.currency = currency;
    },
    setVerificationStatus(state, verificationStatus) {
      state.verificationStatus = verificationStatus;
    },
    setPlatforms(state, platforms) {
      state.platforms = platforms;
    },
    setAccountStatus(state, accountStatus) {
      state.accountStatus = accountStatus;
    },
    setProcessingStatus(state, processingStatus) {
      state.processingStatus = processingStatus;
    },
    setDefaultLeverages(state, defaultLeverages) {
      state.defaultLeverages = defaultLeverages;
    },
    setDepositMerchant(state, depositMerchant) {
      state.depositMerchant = depositMerchant;
    },

    setDefaultPlatform(state, defaultPlatform) {
      state.defaultPlatform = defaultPlatform;
    },

    setQiwiPayment(state, qiwiPayment) {
      state.qiwiPayment = qiwiPayment;
    },
    setPayRequestStatus(state, payRequestStatus) {
      state.payRequestStatus = payRequestStatus;
    },
    setPayRequestType(state, payRequestType) {
      state.payRequestType = payRequestType;
    },
    setRoles(state, roles) {
      state.roles = roles;
    },
  },
  actions: {
    getConstants({ commit, dispatch }, { callback, callbackFailed }) {
      Vue.http
        .get(GET_CONSTANTS_URL())
        .then((response) => {
          response.json().then((response) => {
            console.log("CONSTANTS:");
            console.log(response);
            commit("setRoles", response.data.Role);
            commit("setCurrency", response.data.Currency);
            commit("setVerificationStatus", response.data.VerificationStatus);
            commit("setPlatforms", response.data.Platform);
            commit("setProcessingStatus", response.data.ProcessingStatus);
            commit("setPhoneStatus", response.data.PhoneStatus);
            commit("setAccountStatus", response.data.AccountStatus);
            commit("setDefaultLeverages", response.data.Leverage);
            commit("setDepositMerchant", response.data.Constant.find((item) => item.name === "Current Deposit Merchant"));
            commit("setDefaultPlatform", response.data.Constant.find((item) => item.name === "Default platform"));
            commit("setPayRequestStatus", response.data.PayRequestStatus);
            commit("setPayRequestType", response.data.PayRequestType);
            commit("setQiwiPayment", response.data.Constant.find((item) => item.name === "Current Qiwi Payment Link"));

            if (typeof callback === "function") {
              callback();
            }
          });
        })
        .catch((e) => {
          console.error(e);
          RemoveAllCookies();
          dispatch("logout");
          if (typeof callbackFailed === "function") {
            callbackFailed();
          }
        });
    },
  },
};
