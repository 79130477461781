export default {
  name: "TabMixin",
  data: () => ({}),
  methods: {
    tabWatcherHandler() {
      console.log("fake watcher handler");
    },
  },
  watch: {
    $route(value) {
      if (this.tabIndex === value.params.tab) {
        this.tabWatcherHandler();
      }
    },
  },
  props: {
    tabIndex: Number,
  },
};
