import Vue from "vue";
import Vuex from "vuex";

import accountModule from "../store/account.js";
import constantsModule from "../store/constants.js";
import pages from "../store/pages.js";
import alert from "../store/alert.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accountModule,
    constantsModule,
    pages,
    alert,
  },
});
