<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="card-container-pop-up px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Изменить статус обработки </span>
        </v-col>
      </v-row>
      <v-form
        v-if="itemCopy"
        v-model="isValid"
        @submit.prevent="submitHandler"
        ref="form"
      >
        <v-row
          v-if="
            isSuperAdminPermission ||
            iAmSale ||
            iAmRet ||
            iAmRetTeam ||
            iAmSaleTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'processingStatusId', $event)"
              :items="getProcessedStatuses"
              :value="itemCopy.processingStatusId"
              :item-text="(item) => renderProcessingcStatus(item)"
              item-value="id"
              label="Статус обработки"
              placeholder="Статус обработки"
              :rules="[...rules.notNull]"
              :loading="isRequestManagerWaiting"
              ref="processingStatus"
              @dblclick="$refs.processingStatus.blur()"
            >
              <template v-slot:selection="data">
                <v-badge
                  inline
                  left
                  dot
                  :color="processingStatusColor(data.item.id)"
                >
                  {{ data.item.name }}
                </v-badge>
              </template>

              <template v-slot:item="data">
                <v-badge
                  inline
                  left
                  dot
                  :color="processingStatusColor(data.item.id)"
                >
                  {{ data.item.name }}
                </v-badge>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mt-4" no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>

    <ConfirmPopUp
      :question="'Вы действительно хотите удалить этот телефон?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deletePhone"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-dialog>
</template>

<script>
import BigNumber from "bignumber.js";
import {
  GET_USER_URL,
  GET_ALL_USERS_URL,
  GET_UPDATE_USER_URL,
  GET_UPDATE_PASSWORD_URL,
  GET_ALL_CALL_CENTERS_URL,
  // GET_CALL_CENTER_URL,
  // GET_TEAMS_IN_CALLCENTER_URL,
  GET_ALL_ADMINS_IN_CALLCENTER_URL,
} from "../../config/http";

import PopUpMixin from "../../mixins/PopUpMixin";
import RoleMixin from "../../mixins/RoleMixin";
import ProcessingStatusMixin from "../../mixins/ProcessingStatusMixin";

import ConfirmPopUp from "./ConfirmPopUp.vue";

export default {
  name: "EditUserPopUp",
  mixins: [PopUpMixin, RoleMixin, ProcessingStatusMixin],
  data: () => ({
    // teams: [],
    callCenters: [],
    isClient: [
      { value: true, name: "Клиент" },
      { value: false, name: "Лид" },
    ],
    headers: [
      {
        text: "Phone",
        align: "start",
        sortable: false,
        groupable: false,
        value: "phone",
      },
      {
        text: "",
        value: "delete",
        sortable: false,
        groupable: false,
        width: "20px",
      },
    ],
    affManagers: [],
    retManagers: [],
    saleManagers: [],
    isRequestCallCentersWaiting: false,
    isRequestIsClientWaiting: false,
    isRequestTeamsWaiting: false,
    isRequestManagerWaiting: false,
    isEditBalanceOpened: false,
    isEditDocumentsOpened: false,
    isEditCreditOpened: false,
    newPassword: "",
    selectedPhone: null,
    isDeletePopUpOpened: false,
    currentPhone: "",
  }),
  methods: {
    submitAction() {
      const body = {
        email: this.itemCopy.email,
        phone: this.itemCopy.phone,
        firstName: this.itemCopy.firstName,
        lastName: this.itemCopy.lastName,
        country: this.itemCopy.country,
        callCenterId: this.itemCopy.callCenterId,
        isClient: this.itemCopy.isClient,
        teamId: this.itemCopy.teamId,
        affManagerAdminId: this.itemCopy.affManagerAdminId,
        retAdminId: this.itemCopy.retAdminId,
        saleAdminId: this.itemCopy.saleAdminId,
        processingStatusId: this.itemCopy.processingStatusId,
        phoneStatusId: this.itemCopy.phoneStatusId,
        ipAddress: this.itemCopy.ipAddress,
        accountStatusId: this.itemCopy.accountStatusId,
        comment: this.itemCopy.comment,
      };
      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            this.isRequestWaiting = false;
            this.$emit("update", response.data.items[0]);
            this.$emit("close");
            this.$emit("refresh");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        // this.teams = [];
        this.callCenters = [];
        this.SaleAdmins = [];
        this.financeManagers = [];
        if (this.isSuperSuperAdminPermission || this.iAmCRMManager) {
          this.getCallCentersFromApi();
          this.getAdminsForCallCenter();
        }
        if (!this.iAmSupport && !this.iAmSale) {
          this.getAdminsForCallCenter();
          // this.getTeamsForCallCenter();
        }
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
    getCallCentersFromApi() {
      this.callCenters = [];
      this.isRequestCallCentersWaiting = true;
      this.$http
        .get(GET_ALL_CALL_CENTERS_URL())
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.callCenters = data.data.items;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestCallCentersWaiting = false;
        });
    },
    // getTeamsForCallCenter() {
    //   this.isRequestTeamsWaiting = true;
    //   const params = {
    //     callCenterId: this.itemCopy.callCenterId,
    //   };
    //   this.$http
    //     .get(GET_TEAMS_IN_CALLCENTER_URL(), { params })
    //     .then((response) => {
    //       response.json().then((data) => {
    //         console.log(data);
    //         this.teams = data.data.items;
    //       });
    //     })
    //     .catch((e) => {
    //       console.error(e.message);
    //     })
    //     .finally(() => {
    //       this.isRequestTeamsWaiting = false;
    //     });
    // },
    getAdminsForCallCenter() {
      this.isRequestManagerWaiting = true;
      const params = {
        callCenterId: this.itemCopy.callCenterId,
      };
      // console.log(params);
      this.$http
        .get(GET_ALL_ADMINS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.affManagers = data.data.items.filter((item) => {
              return item.roleId === this.getAffManagerRole.id;
            });
            this.retManagers = data.data.items.filter((item) => {
              return item.roleId === this.getRetRole.id;
            });
            this.saleManagers = data.data.items.filter((item) => {
              return item.roleId === this.getSaleRole.id;
            });
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestManagerWaiting = false;
        });
    },
    onCallCenterChanged(value) {
      // console.log(value);
      if (value !== null) {
        // this.teams = [];
        this.admins = [];
        // this.getTeamsForCallCenter();
        this.getAdminsForCallCenter();
      }
    },
    updateHandler(itemCopy) {
      this.itemCopy = itemCopy;
      this.$emit("update", this.itemCopy);
    },
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    patchUser(array) {
      const body = {
        documents: array,
      };
      this.$http
        .patch(GET_USER_URL(this.selectedItem.id), body)
        .then((response) => {
          response.json().then((data) => {
            // console.log(data);
            this.itemCopy.documents = data.data.documents;

            const params = this.$parent.getQueryParams();
            this.$http
              .get(GET_ALL_USERS_URL(), { params })
              .then((response) => {
                response.json().then((data) => {
                  // console.log(data);
                  const response = data.data;
                  this.$parent.items = response.items;
                  this.$parent.totalItems = response.count;
                  this.$parent.isRequestWaiting = false;
                });
              })
              .catch((e) => {
                console.error(e.message);
                this.isRequestWaiting = false;
              });

            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e);
          this.isRequestWaiting = false;
        });
    },
    updateCreditHandle(value) {
      // console.log(this.itemCopy);
      this.itemCopy.credit = value;

      this.$emit("update", this.itemCopy);
    },
    updatePassword() {
      this.isRequestWaiting = true;
      const body = {
        password: this.newPassword,
      };
      this.$http
        .post(GET_UPDATE_PASSWORD_URL(this.selectedItem.id), body)
        .then((response) => {
          response.json().then(() => {
            // console.log(data);
            this.isRequestWaiting = false;

            this.$store.commit("alert/setAlert", {
              message: "Пароль успешно изменен",
              color: "green",
              icon: "key",
            });
          });
        })
        .catch((e) => {
          console.error(e);
          this.isRequestWaiting = false;
        });
    },
    openDeletePopUp(item) {
      this.selectedPhone = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedPhone = null;
    },

    addPhone() {
      this.isRequestWaiting = true;

      let phones = this.itemCopy.additionalPhones.filter((item) => {
        return item !== this.currentPhone;
      });

      phones.push(this.currentPhone);
      this.currentPhone = "";

      const body = {
        additionalPhones: phones,
      };
      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            this.itemCopy = response.data.items[0];
            this.$emit("refresh");
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },

    deletePhone() {
      this.isRequestWaiting = true;

      let phones = this.itemCopy.additionalPhones.filter((item) => {
        return item !== this.selectedPhone.phone;
      });

      const body = {
        additionalPhones: phones,
      };
      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            // console.log(response);
            this.itemCopy = response.data.items[0];
            this.isDeletePopUpOpened = false;
            this.$emit("refresh");
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    phoneClickHandler(item) {
      // console.log(item);
      navigator.clipboard.writeText(item.phone);
    },
  },
  computed: {
    getCountries() {
      return this.$store.getters.countries;
    },
    getProcessedStatuses() {
      let res = this.$store.getters.processingStatus;

      if (
        this.iAmSuperSuperAdmin ||
        this.iAmSuperAdmin ||
        this.iAmCRMManager ||
        this.iAmSupport
      ) {
        // if (this.itemCopy.isClient)
        //   res = res.filter((item) => {
        //     return item.isClient === true;
        //   });
        // else
        //   res = res.filter((item) => {
        //     return item.isClient === false;
        //   });
      } else if (this.iAmRet || this.iAmRetTeam) {
        if (this.itemCopy.isClient)
          res = res.filter((item) => {
            return item.isClient === true;
          });
      } else if (
        this.iAmSale ||
        this.iAmAffManager ||
        this.iAmAffTeam ||
        this.iAmSaleTeam ||
        this.iAmSaleHead
      ) {
        if (!this.itemCopy.isClient)
          res = res.filter((item) => {
            return item.isClient === false;
          });
      }

      // if (this.itemCopy.isClient)
      //   res = res.filter((item) => {
      //     return item.isClient === true;
      //   });
      // else
      //   res = res.filter((item) => {
      //     return item.isClient === false;
      //   });

      return res;
    },
    getPhoneStatuses() {
      return this.$store.getters.phoneStatus.map((item) => {
        item["disabled"] = item.id === 3 || item.id === 4 ? true : false;
        return item;
      });
    },
    getAccountStatuses() {
      return this.$store.getters.accountStatus;
    },
    getVerificationStatuses() {
      return this.$store.getters.verificationStatus;
    },
    additionalPhones() {
      let additionalPhones = [];
      this.itemCopy.additionalPhones.map((item, index) => {
        additionalPhones.push({
          phone: item,
          id: index,
        });
      });

      return additionalPhones;
    },
  },
  components: {
    ConfirmPopUp,
  },
};
</script>

<style>
.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-text-field.v-text-field--is-booted
  > div
  > div
  > div
  > input {
  color: rgba(0, 0, 0, 0.87);
}
</style>
