<template>
  <v-dialog
    v-model="isOpened"
    max-width="600"
    @click:outside="closePopUp"
    persistent
  >
    <v-card class="card-container-pop-up px-6 py-4">
      <v-row>
        <v-col align="end">
          <v-btn @click="closePopUp" icon>
            <v-icon color="black"> mdi-close </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <v-col cols="auto" align="center">
          <span class="text-h6"> Изменить аккаунт </span>
        </v-col>
      </v-row>
      <v-form
        v-if="itemCopy"
        v-model="isValid"
        @submit.prevent="submitHandler"
        ref="form"
      >
        <v-row
          v-if="
            isSuperAdminPermission ||
            iAmSupport ||
            iAmRetTeam ||
            iAmSaleTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
          no-gutters
        >
          <v-col>
            <v-text-field
              @input="updateLocalItem(itemCopy, 'email', $event)"
              :value="itemCopy.email"
              type="email"
              label="Email"
              placeholder="Email"
              :rules="[...rules.required, ...rules.noSpaces, ...rules.email]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSuperAdminPermission || iAmSupport || iAmRetHead || iAmSaleHead
          "
          no-gutters
        >
          <v-col class="pr-2" cols="6">
            <v-text-field
              @input="updateLocalItem(itemCopy, 'firstName', $event)"
              :value="itemCopy.firstName"
              label="Имя"
              placeholder="Имя"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
          <v-col class="pl-2" cols="6">
            <v-text-field
              @input="updateLocalItem(itemCopy, 'lastName', $event)"
              :value="itemCopy.lastName"
              label="Фамилия"
              placeholder="Фамилия"
              :rules="[...rules.required, ...rules.noSpaces]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSuperAdminPermission ||
            iAmSaleTeam ||
            iAmSaleHead ||
            iAmSupport ||
            iAmRetTeam ||
            iAmSaleTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
          no-gutters
          align="center"
        >
          <v-col class="pr-2">
            <v-text-field
              v-model="newPassword"
              type="text"
              label="Password"
              placeholder="Password"
              :rules="[...rules.noSpaces]"
            ></v-text-field>
          </v-col>
          <v-col align="end" cols="auto">
            <v-btn @click="updatePassword" color="primary">
              Изменить пароль
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="isSuperAdminPermission || iAmRetHead"
          class="mb-2"
          align="center"
          justify="space-between"
          no-gutters
        >
          <v-col cols="7">
            <span>{{ `Баланс: ${parseBigNumber(itemCopy.balance)}` }}</span>
          </v-col>
          <v-col align="end" cols="5" v-if="isSuperAdminPermission">
            <v-sheet class="d-flex flex-row-reverse">
              <v-sheet>
                <v-btn @click="handleEditBalanceOpened()" color="primary">
                    Изменить
                  </v-btn>
              </v-sheet>
              <v-sheet class="mr-2">
                <v-btn v-bind="props" @click="handleFTDOpened()" color="primary" :disabled="!getFirstTimeDepositAvailable()">
                  FTD
                </v-btn>
              </v-sheet>
            </v-sheet>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSuperAdminPermission ||
            iAmSaleHead ||
            iAmSupport ||
            iAmRetTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
          class="mb-2"
          align="center"
          justify="space-between"
          no-gutters
        >
          <v-col cols="9">
            <span>{{ `Кредит: ${parseBigNumber(itemCopy.credit)}` }}</span>
          </v-col>
          <v-col align="end" cols="3">
            <v-btn @click="isEditCreditOpened = true" color="primary">
              Изменить
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="isSuperAdminPermission" no-gutters>
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'country', $event)"
              :items="getCountries"
              item-text="name"
              item-value="code"
              :value="itemCopy.country"
              label="Страна"
              placeholder="Страна"
              :rules="[...rules.notNull]"
              ref="country"
              @dblclick="$refs.country.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSuperAdminPermission ||
            iAmSupport ||
            iAmRetTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
          no-gutters
        >
          <v-col class="pr-2" cols="6">
            <v-text-field-simplemask
              @input="updateLocalItem(itemCopy, 'phone', $event)"
              :value="itemCopy.phone"
              label="Номер телефона"
              placeholder="Номер телефона"
              :properties="{
                rules: [...rules.required, ...rules.onlyNumber],
              }"
              :options="{
                inputMask: '+###-###-###-##-##',
                outputMask: '#############',
                alphanumeric: false,
              }"
            ></v-text-field-simplemask>
          </v-col>
          <v-col class="pl-2" cols="6">
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'phoneStatusId', $event)"
              :value="itemCopy.phoneStatusId"
              :items="getPhoneStatuses"
              item-text="name"
              item-value="id"
              label="Статус верификации телефона"
              placeholder="Статус верификации телефона"
              ref="phoneStatus"
              @dblclick="$refs.phoneStatus.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSuperAdminPermission || iAmRetTeam || iAmRetHead || iAmSaleHead
          "
          no-gutters
        >
          <v-col>
            <v-card class="pa-5 mt-0 mb-6" elevation="1">
              <span class="font-weight-bold">Дополнительные телефоны:</span>

              <v-data-table
                dense
                :headers="headers"
                :items="additionalPhones"
                item-key="id"
                sort-by="createdAt"
                :items-per-page="5"
                @click:row="phoneClickHandler"
              >
                <template v-slot:top>
                  <v-row align="center">
                    <v-col cols="9">
                      <!-- <v-text-field
                        label="add phone"
                        v-model="currentPhone"
                      ></v-text-field> -->
                      <v-text-field-simplemask
                        v-model="currentPhone"
                        :value="currentPhone"
                        label="Номер телефона"
                        placeholder="Номер телефона"
                        :properties="{
                          rules: [...rules.onlyNumber],
                        }"
                        :options="{
                          inputMask: '+###-###-###-##-##',
                          outputMask: '#############',
                          alphanumeric: false,
                        }"
                      ></v-text-field-simplemask>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        elevation="2"
                        tile
                        :disabled="currentPhone.length < 1"
                        @click="addPhone"
                      >
                        send
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:item.phone="{ item }">
                  <v-text-field-simplemask
                    :value="item.phone"
                    label=""
                    :placeholder="item.phone"
                    :properties="{
                      rules: [...rules.onlyNumber],
                      disabled: true,
                    }"
                    :options="{
                      inputMask: '+###-###-###-##-##',
                      outputMask: '#############',
                      alphanumeric: false,
                    }"
                  ></v-text-field-simplemask>
                </template>
                <template v-slot:item.delete="{ item }">
                  <v-btn
                    v-if="isSuperAdminPermission || iAmCRMManager"
                    class="white--text"
                    @click.stop="openDeletePopUp(item)"
                    color="red"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row
          v-if="isSuperAdminPermission || iAmRetTeam || iAmSaleTeam"
          no-gutters
        >
          <v-col>
            <v-text-field
              @input="updateLocalItem(itemCopy, 'ipAddress', $event)"
              :value="itemCopy.ipAddress"
              label="IP Адрес"
              placeholder="IP Адрес"
              :rules="[]"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="mt-4" no-gutters v-if="isSuperAdminPermission">
          <v-col class="pa-1" cols="12">
            <v-btn color="primary" @click="isEditDocumentsOpened = true" block>
              Перейти к документам
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-if="isSuperAdminPermission || iAmRetTeam || iAmSaleHead"
          no-gutters
        >
          <v-col>
            <v-textarea
              @input="updateLocalItem(itemCopy, 'comment', $event)"
              :value="itemCopy.comment"
              label="Комментарий"
              placeholder="Комментарий"
              rows="2"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission ||
              iAmSaleHead ||
              iAmCRMManager ||
              iAmSaleTeam ||
              iAmRetHead ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'isFrozen', $event)"
              :value="itemCopy.isFrozen"
              :items="isFrozen"
              item-text="name"
              item-value="value"
              label="Заморозить:"
              placeholder=""
              :loading="isRequestIsFrozen"
              ref="isFrozen"
              @dblclick="$refs.isClient.blur()"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission || iAmSupport || iAmRetHead || iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'accountStatusId', $event)"
              :value="itemCopy.accountStatusId"
              :items="getAccountStatuses"
              item-text="name"
              item-value="id"
              label="Статус аккаунта"
              placeholder="Статус аккаунта"
              :loading="isRequestCallCentersWaiting"
              ref="accountStatus"
              @dblclick="$refs.accountStatus.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="isSuperAdminPermission || iAmSupport || iAmCRMManager"
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'verificationStatusId', $event)"
              :value="itemCopy.verificationStatusId"
              :items="getVerificationStatuses"
              item-text="name"
              item-value="id"
              label="Статус верификации"
              placeholder="Статус верификации"
              :loading="isRequestCallCentersWaiting"
              ref="verificationStatus"
              @dblclick="$refs.verificationStatus.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="!iAmAffTeam && !iAmAffManager"
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'platformId', $event)"
              :value="itemCopy.platformId"
              :items="getPlatformList"
              item-text="name"
              item-value="id"
              label="Платформа"
              placeholder="Платформа"
              :loading="isRequestCallCentersWaiting"
              ref="platform"
              @dblclick="$refs.platform.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="itemCopy && (isSuperSuperAdminPermission || iAmCRMManager)"
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @change="onCallCenterChanged"
              @input="updateLocalItem(itemCopy, 'callCenterId', $event)"
              :value="itemCopy.callCenterId"
              :items="callCenters"
              item-text="name"
              item-value="id"
              label="Колл-центр"
              placeholder="Колл-центр"
              :loading="isRequestCallCentersWaiting"
              ref="callCenter"
              @dblclick="$refs.callCenter.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            (isSuperAdminPermission ||
              iAmSaleHead ||
              iAmCRMManager ||
              iAmSaleTeam ||
              iAmRetHead ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'isClient', $event)"
              :value="itemCopy.isClient"
              :items="isClient"
              item-text="name"
              item-value="value"
              label="Лид/клиент:"
              placeholder="Назначить лидом/клиентом"
              :loading="isRequestIsClientWaiting"
              ref="isClient"
              @dblclick="$refs.isClient.blur()"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row
          v-if="
            itemCopy &&
            itemCopy.callCenterId !== null &&
            (iAmCRMManager ||
              iAmRetTeam ||
              iAmSaleTeam ||
              iAmRetHead ||
              iAmSaleHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'teamId', $event)"
              :items="teams"
              :value="itemCopy.teamId"
              item-text="name"
              item-value="id"
              label="Команда"
              placeholder="Команда"
              :loading="isRequestTeamsWaiting"
              :rules="[itemCopy.callCenterId || 'Выберите Колл-центр']"
              ref="team"
              @dblclick="$refs.team.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row> -->
        <v-row
          v-if="
            itemCopy &&
            itemCopy.callCenterId &&
            (isSuperAdminPermission || iAmCRMManager)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'affManagerAdminId', $event)"
              :items="affManagers"
              :value="itemCopy.affManagerAdminId"
              :item-text="
                (item) => `${item.firstName} ${item.lastName}`
              "
              item-value="id"
              label="Aff Manager"
              placeholder="Aff Manager"
              :loading="isRequestManagerWaiting"
              ref="affManagerAdmin"
              @dblclick="$refs.affManagerAdmin.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            itemCopy.callCenterId &&
            (isSuperAdminPermission ||
              iAmCRMManager ||
              iAmRetTeam ||
              iAmRetHead)
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'retAdminId', $event)"
              :items="retManagers"
              :value="itemCopy.retAdminId"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} (${item.email})`
              "
              item-value="id"
              label="retAdmin"
              placeholder="retAdmin"
              :loading="isRequestManagerWaiting"
              ref="retAdmin"
              @dblclick="$refs.retAdmin.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            itemCopy &&
            itemCopy.callCenterId &&
            (isSuperAdminPermission ||
              iAmCRMManager ||
              iAmSaleTeam ||
              iAmSaleHead
              )
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'saleAdminId', $event)"
              :items="saleManagers"
              :value="itemCopy.saleAdminId"
              :item-text="
                (item) => `${item.firstName} ${item.lastName} (${item.email})`
              "
              item-value="id"
              label="Sale Manager"
              placeholder="Sale Manager"
              :loading="isRequestManagerWaiting"
              ref="saleAdmin"
              @dblclick="$refs.saleAdmin.blur()"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row
          v-if="
            isSuperAdminPermission ||
            iAmSale ||
            iAmRet ||
            iAmRetTeam ||
            iAmSaleTeam ||
            iAmRetHead ||
            iAmSaleHead
          "
          no-gutters
        >
          <v-col>
            <v-autocomplete
              @input="updateLocalItem(itemCopy, 'processingStatusId', $event)"
              :items="getProcessedStatuses"
              :value="itemCopy.processingStatusId"
              :item-text="(item) => renderProcessingcStatus(item)"
              item-value="id"
              label="Статус обработки"
              placeholder="Статус обработки"
              :rules="[...rules.notNull]"
              :loading="isRequestManagerWaiting"
              ref="processingStatus"
              @dblclick="$refs.processingStatus.blur()"
            >
              <template v-slot:selection="data">
                <v-badge
                  inline
                  left
                  dot
                  :color="processingStatusColor(data.item.id)"
                >
                  {{ data.item.name }}
                </v-badge>
              </template>

              <template v-slot:item="data">
                <v-badge
                  inline
                  left
                  dot
                  :color="processingStatusColor(data.item.id)"
                >
                  {{ data.item.name }}
                </v-badge>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row class="mt-4" no-gutters>
          <v-col class="pa-1" cols="12">
            <v-btn
              color="primary"
              type="submit"
              :loading="isRequestWaiting"
              block
            >
              Сохранить
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
    <EditBalanceChoosePopUp
      :isOpened="isEditBalanceOpened"
      :isCredit="false"
      :selectedItem="itemCopy"
      :initialBalanceChange="initialBalanceChange"
      @update="updateHandler"
      @close="
        () => {
          isEditBalanceOpened = false;
          this.initialBalanceChange = 0;
        }
      "
    ></EditBalanceChoosePopUp>
    <EditBDocumentsPopUp
      :isOpened="isEditDocumentsOpened"
      :selectedItem="itemCopy"
      @close="isEditDocumentsOpened = false"
      @patchUser="patchUser($event)"
    ></EditBDocumentsPopUp>
    <EditCreditPopUp
      :isOpened="isEditCreditOpened"
      :selectedItem="itemCopy"
      @submit="updateCreditHandle"
      @update="$emit('update', $event)"
      @close="isEditCreditOpened = false"
    ></EditCreditPopUp>
    <ConfirmPopUp
      :question="'Вы действительно хотите удалить этот телефон?'"
      :isOpened="isDeletePopUpOpened"
      @yesAction="deletePhone"
      @noAction="closeDeletePopUp"
      @close="closeDeletePopUp"
    ></ConfirmPopUp>
  </v-dialog>
</template>

<script>
import BigNumber from "bignumber.js";
import {
  GET_USER_URL,
  GET_ALL_USERS_URL,
  GET_UPDATE_USER_URL,
  GET_UPDATE_PASSWORD_URL,
  GET_ALL_CALL_CENTERS_URL,
  // GET_CALL_CENTER_URL,
  // GET_TEAMS_IN_CALLCENTER_URL,
  GET_ALL_ADMINS_IN_CALLCENTER_URL,
} from "../../../config/http";

import PopUpMixin from "../../../mixins/PopUpMixin";
import RoleMixin from "../../../mixins/RoleMixin";
import ProcessingStatusMixin from "../../../mixins/ProcessingStatusMixin";

import EditBalanceChoosePopUp from "./EditBalanceChoosePopUp.vue";
import EditBDocumentsPopUp from "./EditDocumentsPopUp.vue";
import EditCreditPopUp from "./EditCreditPopUp.vue";
import ConfirmPopUp from "../../shared/ConfirmPopUp.vue";

const MAX_BALANCE_FOR_FTD = 100;

export default {
  name: "EditUserPopUp",
  mixins: [PopUpMixin, RoleMixin, ProcessingStatusMixin],
  data: () => ({
    // teams: [],
    callCenters: [],
    isClient: [
      { value: true, name: "Клиент" },
      { value: false, name: "Лид" },
    ],
    isFrozen: [
      { value: true, name: "Да" },
      { value: false, name: "Нет" },
    ],
    headers: [
      {
        text: "Phone",
        align: "start",
        sortable: false,
        groupable: false,
        value: "phone",
      },
      {
        text: "",
        value: "delete",
        sortable: false,
        groupable: false,
        width: "20px",
      },
    ],
    affManagers: [],
    retManagers: [],
    saleManagers: [],
    isRequestCallCentersWaiting: false,
    isRequestIsClientWaiting: false,
    isRequestIsFrozen: false,
    isRequestTeamsWaiting: false,
    isRequestManagerWaiting: false,
    isEditBalanceOpened: false,
    isEditDocumentsOpened: false,
    isEditCreditOpened: false,
    newPassword: "",
    selectedPhone: null,
    isDeletePopUpOpened: false,
    currentPhone: "",
    initialBalanceChange: 0,
  }),
  methods: {
    submitAction() {
      const body = {
        email: this.itemCopy.email,
        phone: this.itemCopy.phone,
        firstName: this.itemCopy.firstName,
        lastName: this.itemCopy.lastName,
        country: this.itemCopy.country,
        callCenterId: this.itemCopy.callCenterId,
        isClient: this.itemCopy.isClient,
        teamId: this.itemCopy.teamId,
        affManagerAdminId: this.itemCopy.affManagerAdminId,
        retAdminId: this.itemCopy.retAdminId,
        saleAdminId: this.itemCopy.saleAdminId,
        processingStatusId: this.itemCopy.processingStatusId,
        phoneStatusId: this.itemCopy.phoneStatusId,
        ipAddress: this.itemCopy.ipAddress,
        accountStatusId: this.itemCopy.accountStatusId,
        comment: this.itemCopy.comment,
        isFrozen: this.itemCopy.isFrozen,
        platformId: this.itemCopy.platformId,
      };
      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.isRequestWaiting = false;
            this.$emit("update", response.data.items[0]);
            this.$emit("close");
            this.$emit("refresh");
          });
        })
        .catch((e) => {
          console.error(e.message);
          this.isRequestWaiting = false;
        });
    },
    handleFTDOpened() {
      this.initialBalanceChange = MAX_BALANCE_FOR_FTD - this.itemCopy.balance;
      this.isEditBalanceOpened = true;
    },
    handleEditBalanceOpened() {
      this.isEditBalanceOpened = true;
      this.initialBalanceChange = 0;
    },
    getFirstTimeDepositAvailable() {
      return this.itemCopy.balance < MAX_BALANCE_FOR_FTD && this.itemCopy.balance >= 0;
    },
    isOpenedWatcher(value) {
      if (value) {
        this.isRequestWaiting = false;
        this.isValid = false;
        if (this.selectedItem) {
          this.itemCopy = { ...this.selectedItem };
        }
        // this.teams = [];
        this.callCenters = [];
        this.SaleAdmins = [];
        this.financeManagers = [];
        if (this.isSuperSuperAdminPermission || this.iAmCRMManager) {
          this.getCallCentersFromApi();
          this.getAdminsForCallCenter();
        }
        if (!this.iAmSupport && !this.iAmSale) {
          this.getAdminsForCallCenter();
          // this.getTeamsForCallCenter();
        }
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },
    getCallCentersFromApi() {
      this.callCenters = [];
      this.isRequestCallCentersWaiting = true;
      this.$http
        .get(GET_ALL_CALL_CENTERS_URL())
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.callCenters = data.data.items;
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestCallCentersWaiting = false;
        });
    },
    // getTeamsForCallCenter() {
    //   this.isRequestTeamsWaiting = true;
    //   const params = {
    //     callCenterId: this.itemCopy.callCenterId,
    //   };
    //   this.$http
    //     .get(GET_TEAMS_IN_CALLCENTER_URL(), { params })
    //     .then((response) => {
    //       response.json().then((data) => {
    //         console.log(data);
    //         this.teams = data.data.items;
    //       });
    //     })
    //     .catch((e) => {
    //       console.error(e.message);
    //     })
    //     .finally(() => {
    //       this.isRequestTeamsWaiting = false;
    //     });
    // },
    getAdminsForCallCenter() {
      this.isRequestManagerWaiting = true;
      const params = {
        callCenterId: this.itemCopy.callCenterId,
      };
      console.log(params);
      this.$http
        .get(GET_ALL_ADMINS_IN_CALLCENTER_URL(), { params })
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.affManagers = data.data.items.filter((item) => {
              return item.roleId === this.getAffManagerRole.id;
            });
            const retRoleId = this.getRetRole.id;
            const retTeamRoleId = this.getRetTeamRole.id;

            this.retManagers = data.data.items.filter((item) => {
              return item.roleId === retRoleId || item.roleId === retTeamRoleId;
            });
            this.saleManagers = data.data.items.filter((item) => {
              return item.roleId === this.getSaleRole.id;
            });
          });
        })
        .catch((e) => {
          console.error(e.message);
        })
        .finally(() => {
          this.isRequestManagerWaiting = false;
        });
    },
    onCallCenterChanged(value) {
      console.log(value);
      if (value !== null) {
        // this.teams = [];
        this.admins = [];
        // this.getTeamsForCallCenter();
        this.getAdminsForCallCenter();
      }
    },
    updateHandler(itemCopy) {
      this.itemCopy = itemCopy;
      this.$emit("update", this.itemCopy);
    },
    parseBigNumber(number) {
      return new BigNumber(number).toFixed(2);
    },
    patchUser(array) {
      const body = {
        documents: array,
      };
      this.$http
        .patch(GET_USER_URL(this.selectedItem.id), body)
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.itemCopy.documents = data.data.documents;

            const params = this.$parent.getQueryParams();
            this.$http
              .get(GET_ALL_USERS_URL(), { params })
              .then((response) => {
                response.json().then((data) => {
                  console.log(data);
                  const response = data.data;
                  this.$parent.items = response.items;
                  this.$parent.totalItems = response.count;
                  this.$parent.isRequestWaiting = false;
                });
              })
              .catch((e) => {
                console.error(e.message);
                this.isRequestWaiting = false;
              });

            this.isRequestWaiting = false;
          });
        })
        .catch((e) => {
          console.error(e);
          this.isRequestWaiting = false;
        });
    },
    updateCreditHandle(value) {
      console.log(this.itemCopy);
      this.itemCopy.credit = value;
      console.log(this.itemCopy);

      this.$emit("update", this.itemCopy);
    },
    updatePassword() {
      this.isRequestWaiting = true;
      const body = {
        password: this.newPassword,
      };
      this.$http
        .post(GET_UPDATE_PASSWORD_URL(this.selectedItem.id), body)
        .then((response) => {
          response.json().then((data) => {
            console.log(data);
            this.isRequestWaiting = false;

            this.$store.commit("alert/setAlert", {
              message: "Пароль успешно изменен",
              color: "green",
              icon: "key",
            });
          });
        })
        .catch((e) => {
          console.error(e);
          this.$store.commit("alert/setAlert", {
            message: "Не удалось изменить пароль",
            color: "red",
            icon: "key",
          });
          this.isRequestWaiting = false;
        });
    },
    openDeletePopUp(item) {
      this.selectedPhone = item;
      this.isDeletePopUpOpened = true;
    },
    closeDeletePopUp() {
      this.isDeletePopUpOpened = false;
      this.selectedPhone = null;
    },

    addPhone() {
      this.isRequestWaiting = true;

      let phones = this.itemCopy.additionalPhones.filter((item) => {
        return item !== this.currentPhone;
      });

      phones.push(this.currentPhone);
      this.currentPhone = "";

      const body = {
        additionalPhones: phones,
      };
      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.itemCopy = response.data.items[0];
            this.$emit("refresh");
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },

    deletePhone() {
      this.isRequestWaiting = true;

      let phones = this.itemCopy.additionalPhones.filter((item) => {
        return item !== this.selectedPhone.phone;
      });

      const body = {
        additionalPhones: phones,
      };
      this.$http
        .patch(GET_UPDATE_USER_URL(this.itemCopy.id), body)
        .then((response) => {
          response.json().then((response) => {
            console.log(response);
            this.itemCopy = response.data.items[0];
            this.isDeletePopUpOpened = false;
            this.$emit("refresh");
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.isRequestWaiting = false;
        });
    },
    phoneClickHandler(item) {
      console.log(item);
      navigator.clipboard.writeText(item.phone);
    },
  },
  computed: {
    getCountries() {
      return this.$store.getters.countries;
    },
    getProcessedStatuses() {
      let res = this.$store.getters.processingStatus;

      if (
        this.iAmSuperSuperAdmin ||
        this.iAmSuperAdmin ||
        this.iAmCRMManager ||
        this.iAmSupport
      ) {
        // if (this.itemCopy.isClient)
        //   res = res.filter((item) => {
        //     return item.isClient === true;
        //   });
        // else
        //   res = res.filter((item) => {
        //     return item.isClient === false;
        //   });
      } else if (this.iAmRet || this.iAmRetTeam) {
        if (this.itemCopy.isClient)
          res = res.filter((item) => {
            return item.isClient === true;
          });
      } else if (
        this.iAmSale ||
        this.iAmAffManager ||
        this.iAmAffTeam ||
        this.iAmSaleTeam ||
        this.iAmSaleHead
      ) {
        if (!this.itemCopy.isClient)
          res = res.filter((item) => {
            return item.isClient === false;
          });
      }

      // if (this.itemCopy.isClient)
      //   res = res.filter((item) => {
      //     return item.isClient === true;
      //   });
      // else
      //   res = res.filter((item) => {
      //     return item.isClient === false;
      //   });

      return res;
    },
    getPhoneStatuses() {
      return this.$store.getters.phoneStatus.map((item) => {
        item["disabled"] = item.id === 3 || item.id === 4 ? true : false;
        return item;
      });
    },
    getAccountStatuses() {
      return this.$store.getters.accountStatus;
    },
    getVerificationStatuses() {
      return this.$store.getters.verificationStatus;
    },
    getPlatformList() {
      return this.$store.getters.platforms;
    },
    additionalPhones() {
      let additionalPhones = [];
      this.itemCopy.additionalPhones.map((item, index) => {
        additionalPhones.push({
          phone: item,
          id: index,
        });
      });

      return additionalPhones;
    },
  },
  components: {
    EditBalanceChoosePopUp,
    EditBDocumentsPopUp,
    EditCreditPopUp,
    ConfirmPopUp,
  },
};
</script>

<style>
.v-input.v-input--is-label-active.v-input--is-dirty.v-input--is-disabled.theme--light.v-text-field.v-text-field--is-booted
  > div
  > div
  > div
  > input {
  color: rgba(0, 0, 0, 0.87);
}
</style>
